<template>
  <router-view />
  <div class="small text-end px-5 hideOnPrint">
    version {{ major }}.{{ minor }}.{{ build }}
  </div>
</template>

<script>
import versionData from "./version.json";
export default {
  data() {
    return {
      major: versionData.major,
      minor: versionData.minor,
      build: versionData.build,
    };
  },
  mounted: function () {
    console.log(
      "%c Version:" + this.major + "." + this.minor + "." + this.build,
      "background: #222; color: #bada55"
    );
  },
};
</script>

<style lang="scss">
@import "./style/index.scss";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
