import { createStore } from "vuex";
import axios from "axios";

export default createStore({
  state: {
    version: "0.1",
    debug: false,
    price: {
      deurgehelen: [],
      total: 0,
    },
    showPrice: false,
    kaderNaarGlasMaat: {
      Breedte: 18.5, // FLEX oud maar nog in gebruik. Vervang in code met FlexLinks of FlexRechts
      Hoogte: 19, // FLEX oud maar nog in gebruik. Vervang in code met FlexBoven
      FlexBoven: 19,
      FlexLinks: 18.5,
      FlexRechts: 18.5,
      FlexOnder: 66,
      NexLinks: 12,
      NexRechts: 12,
      NexBoven: 12,
      NexOnder: 12,
    },
    //default speling tussen frame en kader
    deurkaderSpelingDefault: {
      NexLinks: 2,
      NexRechts: 3,
      NexBoven: 2,
      NexOnder: 8,
    },
    //speling tussen frame en kader
    deurkaderSpeling: {
      NexLinks: 2,
      NexRechts: 3,
      NexBoven: 2,
      NexOnder: 8,
    },
    deurkaderTypes: [
      {
        type: "dk",
        bestel: 1,
        finishID: 146,
        oorsprong: "RB",
        product: 6656,
        navisioncode: "NEX03ML",
        slijpen: "",
        speling: 8,
        spelingBoven: 2,
        spelingBreedte: 5,
      },
      {
        type: "dk",
        bestel: 1,
        finishID: 146,
        oorsprong: "RB",
        product: 6657,
        navisioncode: "NEX03MR",
        slijpen: "",
        speling: 8,
        spelingBoven: 2,
        spelingBreedte: 5,
      },
      {
        type: "dk",
        bestel: 1,
        finishID: 146,
        oorsprong: "RB",
        product: 3412,
        navisioncode: "GP01M24L",
        slijpen: "",
        speling: 8,
        spelingBoven: 2,
        spelingBreedte: 5,
      },
      {
        type: "dk",
        bestel: 1,
        finishID: 146,
        oorsprong: "RB",
        product: 3413,
        navisioncode: "GP01M24R",
        slijpen: "",
        speling: 8,
        spelingBoven: 2,
        spelingBreedte: 5,
      },
    ],
    orderID: 0,
    redrawFix: 0,
    priceFix: 0,
    hiddenRemark: "",
    sec: {
      user_name: "",
      user_pwd: "",
    },
    token: "INVALID",
    qouteForm: {
      firstname: "",
      lastname: "",
      phone: "",
      mail: "",
      country: "",
      street: "",
      zip: "",
      municipality: "",
      dealer: "",
    },
    order: {
      klant: 6,
      klantref: "",
      orderstatus: "n",
      aklant: 0,
      deurgehelen: [],
    },
    deurgeheelRef: "",
    productAssortiment: 0,
    productType: 1,
    aantalDeuren: 1,
    draairichting: 0,
    draairichtingen: ["Links", "Rechts"],
    deurkaderKeuze: 0,
    gewicht: 0,
    aantalPanelenLinks: 0,
    panelenLinks: [],
    aantalPanelenRechts: 0,
    panelenRechts: [],
    glasmaat: {
      Breedte: 0,
      Hoogte: 0,
    },
    kadermaat: {
      Hoogte: 0,
      Breedte: 0,
    },
    spanmaat: {
      Hoogte: 0,
      Breedte: 0,
    },
    deurkadermaat: {
      Hoogte: 0,
      Breedte: 0,
    },
    heightError: false,
    toonDraaipuntWaarschuwing: false,
    horizontaleOnderverdeling: 0,
    horizontaleOnderverdelingen: [],
    horizontaleOnderverdelingenSym: [], // Voor symetrische onderverdeling op basis van glasmaat.
    verticaleOnderverdeling: 0,
    verticaleOnderverdelingen: [],
    verticaleOnderverdelingenSym: [],
    opvulvlak: 0,
    coverList: [], // {x,y,width,height}
    asymmetrisch: 0,
    glassoortKeuze: 0,
    alustaDeurKeuze: 0,
    draaipuntX: 110,
    slot: 0,
    slotpot: 0,
    cilinderkleur: 0,
    cilindertype: 0,
    slotX: 125,
    middenslot: 0,
    slotplaat: 0,
    kantschuifslot: 0,
    scharnierenPositieType: 0, // NEX (opmaat of standaard afmetingen op de deur)
    scharnieren: [150, 150, 340, 1050], // NEX (posities)
    scharnierType: 0, // NEX (DF voor GP of NEX voor NEX DK)
    scharnierKleur: 0, // Kleur scharnier MAT, Overnemen kleur frame of Inox
    //NEX
    scharnierTypes: [
      {
        type: "hpzu",
        bestel: 1,
        finishID: 146,
        oorsprong: "LB",
        product: 6651,
        navisioncode: "NEX05L",
        slijpen: "",
        y: 0,
      },
      {
        type: "hpzu",
        bestel: 1,
        finishID: 146,
        oorsprong: "LB",
        product: 6652,
        navisioncode: "NEX05R",
        slijpen: "",
        y: 0,
      },
      {
        type: "hpzu",
        bestel: 1,
        finishID: 146,
        oorsprong: "LB",
        product: 1323,
        navisioncode: "DF05L",
        slijpen: "",
        y: 0,
      },
      {
        type: "hpzu",
        bestel: 1,
        finishID: 146,
        oorsprong: "LB",
        product: 1327,
        navisioncode: "DF05R",
        slijpen: "",
        y: 0,
      },
    ],
    lakkleur: 0,
    lakkleuren: [
      "#0e0e10",
      "#f1f0ea",
      "#f1ece1",
      "#383e42",
      "#2f3234",
      "#D7D4D2",
      "#7B9AA1",
      "#75918B",
      "#C6C49D",
      "#B09A6A",
      "#75654C",
      "#514434",
    ],
    lakafwerking: 0,
    finishes: [
      [
        "RAL 9005 structuur Gitzwart / structure Noir foncé",
        "RAL 9005 mat Gitzwart / mat Noir foncé",
        "RAL 9005 blinkend Gitzwart / satin Noir foncé",
      ],
      [
        "RAL 9016 structuur Verkeerswit / structure Blanc signalisation",
        "RAL 9016 mat Verkeerswit / mat Blanc signalisation",
        "RAL 9016 blinkend Verkeerswit / satin Blanc signalisation",
      ],
      [
        "RAL 9010 structuur Zuiverwit / structure Blanc pur",
        "RAL 9010 mat Zuiverwit / mat Blanc pur",
        "RAL 9010 blinkend Zuiverwit / satin Blanc pur",
      ],
      [
        "RAL 7016 structuur Antracietgrijs / structure Gris anthracite",
        "RAL 7016 mat Antracietgrijs / mat Gris anthracite",
        "RAL 7016 blinkend Antracietgrijs / satin Gris anthracite",
      ],
      [
        "RAL 7021 structuur Zwartgrijs / structure Gris noir",
        "RAL 7021 mat Zwartgrijs / mat Gris noir",
        "RAL 7021 blinkend Zwartgrijs / satin Gris noir",
      ],
      [
        "AE20107000120 ANODIC NATURA",
        "AE20107000120 ANODIC NATURA",
        "AE20107000120 ANODIC NATURA",
      ],
      [
        "AE20105000520 ANODIC SAPHIRE",
        "AE20105000520 ANODIC SAPHIRE",
        "AE20105000520 ANODIC SAPHIRE",
      ],
      [
        "AE20106000620 ANODIC MALACHITE",
        "AE20106000620 ANODIC MALACHITE",
        "AE20106000620 ANODIC MALACHITE",
      ],
      [
        "AE20101000220 ANODIC CHAMPAGNE",
        "AE20101000220 ANODIC CHAMPAGNE",
        "AE20101000220 ANODIC CHAMPAGNE",
      ],
      [
        "AE20111000820 ANODIC GOLD",
        "AE20111000820 ANODIC GOLD",
        "AE20111000820 ANODIC GOLD",
      ],
      [
        "AE20108000320 ANODIC BRONZE",
        "AE20108000320 ANODIC BRONZE",
        "AE20108000320 ANODIC BRONZE",
      ],
      [
        "AE20108000420 ANODIC BROWN",
        "AE20108000420 ANODIC BROWN",
        "AE20108000420 ANODIC BROWN",
      ],
    ],
    hendelKeuze: 0,
    hendelPositie: {
      y: 1050,
      x: 0,
    },
    magneetPositie: {
      y: 1050,
      x: 0,
    },
    //Algemeen
    aldv: [
      {
        type: "aldv",
        bestel: 1,
        finishID: 146,
        oorsprong: "LB",
        product: 4343,
        navisioncode: "ASDKIT10",
        slijpen: "",
        x: 110,
      },
      {
        type: "aldv",
        bestel: 1,
        finishID: 146,
        oorsprong: "LB",
        product: 4344,
        navisioncode: "ASDKIT11",
        slijpen: "",
        x: 110,
      },
    ],
    ALA: [
      {
        // 0 SLOT
        type: "ala",
        bestel: 1,
        finishID: 146,
        oorsprong: "RO",
        product: 4463,
        navisioncode: "ASCS01",
        slijpen: "",
        x: 61,
      },
      {
        // 1 SLOTPOT
        type: "cil",
        bestel: 1,
        finishID: 7,
        oorsprong: "RO",
        product: 339,
        navisioncode: "V515",
        slijpen: "",
        x: 61,
      },
      {
        // 2 Cilinder
        type: "cil",
        bestel: 1,
        finishID: 144,
        oorsprong: "RO",
        product: 3754,
        navisioncode: "V017S",
        slijpen: "",
        x: 61,
      },
      {
        // 3 Cilinder
        type: "cil",
        bestel: 1,
        finishID: 144,
        oorsprong: "RO",
        product: 3753,
        navisioncode: "V017D",
        slijpen: "",
        x: 61,
      },
      {
        // 4 Cilinder
        type: "cil",
        bestel: 1,
        finishID: 121,
        oorsprong: "RO",
        product: 3754,
        navisioncode: "V017S",
        slijpen: "",
        x: 61,
      },
      {
        // 5 Cilinder
        type: "cil",
        bestel: 1,
        finishID: 121,
        oorsprong: "RO",
        product: 3753,
        navisioncode: "V017D",
        slijpen: "",
        x: 61,
      },
      {
        // 6 Slotplaat
        type: "kruk",
        bestel: 1,
        finishID: 146,
        oorsprong: "RO",
        product: 341,
        navisioncode: "V532",
        slijpen: "",
        x: 0,
      },
      {
        // 7 Middenslot
        type: "sk",
        bestel: 1,
        finishID: 146,
        oorsprong: "RO",
        product: 5680,
        navisioncode: "AS522",
        slijpen: "",
        x: 0,
      },
      {
        // 8 Kantschuifslot
        type: "dka",
        bestel: 1,
        finishID: 315,
        oorsprong: "RO",
        product: 3539,
        navisioncode: "061.7168",
        slijpen: "",
        x: 0,
      },
      {
        // 9 Magneetsluiting
        type: "rs",
        bestel: 1,
        finishID: 173,
        oorsprong: "RO",
        product: 6667,
        navisioncode: "NXMG01",
        slijpen: "",
        x: 0,
        y: 0,
      },
    ],
    ALCOV: [
      {
        type: "alcov",
        bestel: 1,
        finishID: 146,
        oorsprong: "RO",
        product: 4757,
        navisioncode: "ASCOV",
        slijpen: "",
        x: 0,
        y: 0,
        hoogte: 0,
        breedte: 0,
      },
      {
        type: "alcov",
        bestel: 1,
        finishID: 146,
        oorsprong: "RO",
        product: 6648,
        navisioncode: "NEXCOV",
        slijpen: "",
        x: 0,
        y: 0,
        hoogte: 0,
        breedte: 0,
      },
    ],
    astool1keuze: 0,
    ASTOOL1: [
      {
        type: "ALT",
        bestel: 1,
        finishID: 123,
        oorsprong: "RB",
        product: 4665,
        navisioncode: "ASTOOL1",
        slijpen: "",
      },
      {
        type: "ALT",
        bestel: 1,
        finishID: 123,
        oorsprong: "RB",
        product: 5016,
        navisioncode: "ASTOOL1XL",
        slijpen: "",
      },
    ],
    astool2keuze: 0,
    ASTOOL2: [
      {
        type: "ALT",
        bestel: 1,
        finishID: 123,
        oorsprong: "RB",
        product: 4967,
        navisioncode: "ASTOOL2",
        slijpen: "",
      },
      {
        type: "ALT",
        bestel: 1,
        finishID: 123,
        oorsprong: "RB",
        product: 5015,
        navisioncode: "ASTOOL2XL",
        slijpen: "",
      },
    ],
    greeplengte: 150,
    //Start Grepen
    hendel: [
      {
        //0
        title: "Hoekgreep",
        type: "alg",
        bestel: 1,
        finishID: 146,
        oorsprong: "RO",
        product: 4340,
        navisioncode: "ASHG01",
        slijpen: "",
        p_height: 150,
        greeplengte: 150,
        p_middenslot: 0,
        p_heeftMagneetsluiting: 0,
      },
      {
        //1
        type: "vg",
        title: "Greep: AMT200300",
        afstand: "200",
        bestel: 1,
        diameter: 14,
        finishID: 146,
        oorsprong: "RO",
        product: 48,
        navisioncode: "AMT200300",
        slijpen: "",
        x: 80,
        p_height: 300,
        greeplengte: 300,
        p_middenslot: 1,
        p_heeftMagneetsluiting: 1,
      },
      {
        //2
        type: "vg",
        title: "Greep: AMT200500",
        afstand: "340",
        bestel: 1,
        diameter: 14,
        finishID: 146,
        oorsprong: "RO",
        product: 49,
        navisioncode: "AMT200500",
        slijpen: "",
        x: 80,
        p_height: 500,
        greeplengte: 500,
        p_middenslot: 1,
        p_heeftMagneetsluiting: 1,
      },
      {
        //3
        type: "vg",
        title: "Greep: AMT200700",
        afstand: "540",
        bestel: 1,
        diameter: 14,
        finishID: 146,
        oorsprong: "RO",
        product: 50,
        navisioncode: "AMT200700",
        slijpen: "",
        x: 80,
        p_height: 700,
        greeplengte: 700,
        p_middenslot: 1,
        p_heeftMagneetsluiting: 1,
      },
      {
        //4
        type: "vg",
        title: "Greep: AMT201200",
        afstand: "1040",
        bestel: 1,
        diameter: 14,
        finishID: 146,
        oorsprong: "RO",
        product: 51,
        navisioncode: "AMT201200",
        slijpen: "",
        x: 80,
        p_height: 1200,
        greeplengte: 1200,
        p_middenslot: 1,
        p_heeftMagneetsluiting: 1,
      },
      {
        //5
        type: "vg",
        title: "Greep: AMUR200300SQ",
        afstand: "300",
        bestel: 1,
        diameter: 14,
        finishID: 146,
        oorsprong: "RO",
        product: 1923,
        navisioncode: "AMUR200300SQ",
        slijpen: "",
        x: 80,
        p_height: 300,
        greeplengte: 300,
        p_middenslot: 1,
        p_heeftMagneetsluiting: 1,
      },
      {
        //6
        type: "vg",
        title: "Greep: AMUR200500SQ",
        afstand: "500",
        bestel: 1,
        diameter: 14,
        finishID: 146,
        oorsprong: "RO",
        product: 1921,
        navisioncode: "AMUR200500SQ",
        slijpen: "",
        x: 80,
        p_height: 500,
        greeplengte: 500,
        p_middenslot: 1,
        p_heeftMagneetsluiting: 1,
      },
      {
        //7
        type: "vg",
        title: "Greep: AMUR201200SQ",
        afstand: "1200",
        bestel: 1,
        diameter: 14,
        finishID: 146,
        oorsprong: "RO",
        product: 1925,
        navisioncode: "AMUR201200SQ",
        slijpen: "",
        x: 80,
        p_height: 1200,
        greeplengte: 1200,
        p_middenslot: 1,
        p_heeftMagneetsluiting: 1,
      },
      {
        //8
        type: "vg",
        title: "Greep: AMUR302000SQ",
        afstand: "2000",
        bestel: 1,
        diameter: 14,
        finishID: 146,
        oorsprong: "RO",
        product: 1927,
        navisioncode: "AMUR302000SQ",
        slijpen: "",
        x: 80,
        p_height: 2000,
        greeplengte: 2000,
        p_middenslot: 1,
        p_heeftMagneetsluiting: 1,
      },
      {
        //9
        title: "Hoekgreep",
        type: "alg",
        bestel: 1,
        finishID: 146,
        oorsprong: "RO",
        product: 4979,
        navisioncode: "ASHG300",
        slijpen: "",
        p_height: 300,
        greeplengte: 300,
        p_middenslot: 0,
        p_heeftMagneetsluiting: 0,
      },
      {
        //10
        title: "Hoekgreep",
        type: "alg",
        bestel: 1,
        finishID: 146,
        oorsprong: "RO",
        product: 4980,
        navisioncode: "ASHG500",
        slijpen: "",
        p_height: 500,
        greeplengte: 500,
        p_middenslot: 0,
        p_heeftMagneetsluiting: 0,
      },
      {
        //11
        title: "Hoekgreep",
        type: "alg",
        bestel: 1,
        finishID: 146,
        oorsprong: "RO",
        product: 4981,
        navisioncode: "ASHG1200",
        slijpen: "",
        p_height: 1200,
        greeplengte: 1200,
        p_middenslot: 0,
        p_heeftMagneetsluiting: 0,
      },
      {
        //12
        title: "Hoekgreep",
        type: "alg",
        bestel: 1,
        finishID: 146,
        oorsprong: "RO",
        product: 4982,
        navisioncode: "ASHG2000",
        slijpen: "",
        p_height: 2000,
        greeplengte: 2000,
        p_middenslot: 0,
        p_heeftMagneetsluiting: 0,
      },
      {
        //13
        title: "Hoekgreep",
        type: "alg",
        bestel: 1,
        finishID: 146,
        oorsprong: "RO",
        product: 4755,
        navisioncode: "ASHGMAAT",
        slijpen: "",
        p_height: 150,
        greeplengte: 150,
        p_middenslot: 0,
        p_heeftMagneetsluiting: 0,
      },
      {
        //14
        title: "Hoekgreep",
        type: "sk",
        bestel: 1,
        finishID: 146,
        oorsprong: "RO",
        product: 5681,
        navisioncode: "AS522HG",
        slijpen: "",
        p_height: 100,
        greeplengte: 100,
        p_middenslot: 1,
        p_heeftMagneetsluiting: 0,
      },
      // start NEXHG
      {
        //15
        title: "Hoekgreep",
        type: "alg",
        bestel: 1,
        finishID: 146,
        oorsprong: "RO",
        product: 6694,
        navisioncode: "NEXHG150",
        slijpen: "",
        p_height: 150,
        greeplengte: 150,
        p_middenslot: 0,
        p_heeftMagneetsluiting: 1,
      },
      {
        //16
        title: "Hoekgreep",
        type: "alg",
        bestel: 1,
        finishID: 146,
        oorsprong: "RO",
        product: 6695,
        navisioncode: "NEXHG300",
        slijpen: "",
        p_height: 300,
        greeplengte: 300,
        p_middenslot: 0,
        p_heeftMagneetsluiting: 1,
      },
      {
        //17
        title: "Hoekgreep",
        type: "alg",
        bestel: 1,
        finishID: 146,
        oorsprong: "RO",
        product: 6696,
        navisioncode: "NEXHG500",
        slijpen: "",
        p_height: 500,
        greeplengte: 500,
        p_middenslot: 0,
        p_heeftMagneetsluiting: 1,
      },
      {
        //18
        title: "Hoekgreep",
        type: "alg",
        bestel: 1,
        finishID: 146,
        oorsprong: "RO",
        product: 6697,
        navisioncode: "NEXHG1200",
        slijpen: "",
        p_height: 1200,
        greeplengte: 1200,
        p_middenslot: 0,
        p_heeftMagneetsluiting: 1,
      },
      {
        //19
        title: "Hoekgreep",
        type: "alg",
        bestel: 1,
        finishID: 146,
        oorsprong: "RO",
        product: 6698,
        navisioncode: "NEXHG2000",
        slijpen: "",
        p_height: 2000,
        greeplengte: 2000,
        p_middenslot: 0,
        p_heeftMagneetsluiting: 1,
      },
      {
        //20
        title: "Hoekgreep",
        type: "alg",
        bestel: 1,
        finishID: 146,
        oorsprong: "RO",
        product: 6653,
        navisioncode: "NEXHGM",
        slijpen: "",
        p_height: 300,
        greeplengte: 300,
        p_middenslot: 0,
        p_heeftMagneetsluiting: 1,
      },
      // end NEXHG
      // start NEXEG
      {
        //21
        title: "Hoekgreep",
        type: "alg",
        bestel: 1,
        finishID: 146,
        oorsprong: "RO",
        product: 6699,
        navisioncode: "NEXEG150",
        slijpen: "",
        p_height: 150,
        greeplengte: 150,
        p_middenslot: 0,
        p_heeftMagneetsluiting: 1,
      },
      {
        //22
        title: "Hoekgreep",
        type: "alg",
        bestel: 1,
        finishID: 146,
        oorsprong: "RO",
        product: 6700,
        navisioncode: "NEXEG300",
        slijpen: "",
        p_height: 300,
        greeplengte: 300,
        p_middenslot: 0,
        p_heeftMagneetsluiting: 1,
      },
      {
        //23
        title: "Hoekgreep",
        type: "alg",
        bestel: 1,
        finishID: 146,
        oorsprong: "RO",
        product: 6701,
        navisioncode: "NEXEG500",
        slijpen: "",
        p_height: 500,
        greeplengte: 500,
        p_middenslot: 0,
        p_heeftMagneetsluiting: 1,
      },
      {
        //24
        title: "Hoekgreep",
        type: "alg",
        bestel: 1,
        finishID: 146,
        oorsprong: "RO",
        product: 6702,
        navisioncode: "NEXEG1200",
        slijpen: "",
        p_height: 1200,
        greeplengte: 1200,
        p_middenslot: 0,
        p_heeftMagneetsluiting: 1,
      },
      {
        //25
        title: "Hoekgreep",
        type: "alg",
        bestel: 1,
        finishID: 146,
        oorsprong: "RO",
        product: 6703,
        navisioncode: "NEXEG2000",
        slijpen: "",
        p_height: 2000,
        greeplengte: 2000,
        p_heeftMagneetsluiting: 1,
      },
      {
        //26
        title: "Hoekgreep",
        type: "alg",
        bestel: 1,
        finishID: 146,
        oorsprong: "RO",
        product: 6654,
        navisioncode: "NEXEGM",
        slijpen: "",
        p_height: 300,
        greeplengte: 300,
        p_middenslot: 0,
        p_heeftMagneetsluiting: 1,
      },
      // end NEXEG
      // NEX
      {
        //27
        title: "Kantelkruk NEX",
        type: "sk",
        bestel: 1,
        finishID: 146,
        oorsprong: "RO",
        product: 6655,
        navisioncode: "NXCS01",
        slijpen: "",
        p_height: 100,
        greeplengte: 100,
        p_middenslot: 0,
        p_heeftMagneetsluiting: 0,
      },
      //AMURSQ 2de reeks
      {
        //28
        type: "vg",
        title: "Greep: AMUR100380SQ",
        afstand: "380",
        bestel: 1,
        diameter: 14,
        finishID: 146,
        oorsprong: "RO",
        product: 4993,
        navisioncode: "AMUR100380SQ",
        slijpen: "",
        x: 80,
        p_height: 380,
        greeplengte: 380,
        p_middenslot: 1,
        p_heeftMagneetsluiting: 1,
      },
      {
        //29
        type: "vg",
        title: "Greep: AMUR200700SQ",
        afstand: "700",
        bestel: 1,
        diameter: 14,
        finishID: 146,
        oorsprong: "RO",
        product: 5060,
        navisioncode: "AMUR200700SQ",
        slijpen: "",
        x: 80,
        p_height: 700,
        greeplengte: 700,
        p_middenslot: 1,
        p_heeftMagneetsluiting: 1,
      },
    ],
    //End Grepen
    //Frames
    ALF: [
      // 0 FLEX
      {
        type: "ALF",
        bestel: 1,
        finishID: 146,
        oorsprong: "LB",
        product: 4349,
        navisioncode: "ALUST20",
        slijpen: "",
      },
      // 1 FLEX
      {
        type: "ALF",
        bestel: 1,
        finishID: 146,
        oorsprong: "LB",
        product: 4459,
        navisioncode: "ALUST21",
        slijpen: "",
      },
      // 2 FLEX
      {
        type: "ALF",
        bestel: 1,
        finishID: 146,
        oorsprong: "LB",
        product: 4460,
        navisioncode: "ALUST22",
        slijpen: "",
      },
      //3 NEX
      {
        type: "ALF",
        bestel: 1,
        finishID: 146,
        oorsprong: "LB",
        product: 6704,
        navisioncode: "NEX1",
        slijpen: "",
      },
    ],
    ALSD: [
      {
        type: "ALSD",
        bestel: 1,
        finishID: 146,
        oorsprong: "LB",
        product: 4835,
        navisioncode: "ALUST3",
        slijpen: "",
      },
    ],
    ALOkeuze: 0,
    ALO: [
      {
        type: "ALOH",
        bestel: 1,
        finishID: 146,
        oorsprong: "RO",
        product: 4342,
        navisioncode: "ASDIVH",
        slijpen: "",
        y: 100,
      },
      {
        type: "ALOV",
        bestel: 1,
        finishID: 146,
        oorsprong: "RB",
        product: 4341,
        navisioncode: "ASDIVV",
        slijpen: "",
        x: 100,
      },
      {
        type: "ALOH",
        bestel: 1,
        finishID: 146,
        oorsprong: "RO",
        product: 4617,
        navisioncode: "ASDIVL",
        slijpen: "",
        y: 100,
      },
      {
        type: "ALOV",
        bestel: 1,
        finishID: 146,
        oorsprong: "RB",
        product: 4618,
        navisioncode: "ASDIVS",
        slijpen: "",
        x: 100,
      },
      //NEX DIVIDERS
      {
        type: "ALOH",
        bestel: 1,
        finishID: 146,
        oorsprong: "RO",
        product: 6650,
        navisioncode: "NEXDIVH",
        slijpen: "",
        y: 100,
      },
      {
        type: "ALOV",
        bestel: 1,
        finishID: 146,
        oorsprong: "RB",
        product: 6649,
        navisioncode: "NEXDIVV",
        slijpen: "",
        x: 100,
      },
    ],
    glassoort: [
      {
        title: "Glassoort.Klaar10",
        inNex: false,
        inFlex: true,
        img: "GehardKlaar10.png",
        drawimg: "FloatKlaar.png",
        glassoort: "A",
        type: "H10A",
        glasdikte: "10.0",
        glasdoel: "H",
        slijpen: "RP",
        geenGlas: 0,
        gehard: 1,
        bewerktekant: "",
        draairichting: "N",
      },
      {
        title: "Glassoort.Klaar06",
        inNex: true,
        inFlex: false,
        img: "GehardKlaar10.png",
        drawimg: "FloatKlaar.png",
        glassoort: "A",
        type: "H06A",
        glasdikte: "6.0",
        glasdoel: "HD",
        slijpen: "RP",
        geenGlas: 0,
        gehard: 1,
        bewerktekant: "",
        draairichting: "N",
      },
      {
        title: "Glassoort.Brons06",
        inNex: true,
        inFlex: false,
        img: "GehardGrijs10.png",
        drawimg: "FloatBrons.png",
        glassoort: "B",
        type: "H06B",
        glasdikte: "6.0",
        glasdoel: "HD",
        slijpen: "RP",
        geenGlas: 0,
        gehard: 1,
        bewerktekant: "",
        draairichting: "N",
      },
      {
        title: "Glassoort.Grijs10",
        inNex: false,
        inFlex: true,
        img: "GehardGrijs10.png",
        drawimg: "FloatGrijs.png",
        glassoort: "C",
        type: "H10C",
        glasdikte: "10.0",
        glasdoel: "H",
        slijpen: "RP",
        geenGlas: 0,
        gehard: 1,
        bewerktekant: "",
        draairichting: "N",
      },
      {
        title: "Glassoort.Grijs06",
        inNex: true,
        inFlex: false,
        img: "GehardGrijs10.png",
        drawimg: "FloatGrijs.png",
        glassoort: "C",
        type: "H06C",
        glasdikte: "6.0",
        glasdoel: "HD",
        slijpen: "RP",
        geenGlas: 0,
        gehard: 1,
        bewerktekant: "",
        draairichting: "N",
      },
      {
        title: "Glassoort.AcideKlaar10",
        inNex: false,
        inFlex: true,
        img: "GehardAcideKlaar10.png",
        drawimg: "AcideKlaar.png",
        glassoort: "M",
        type: "H10M",
        glasdikte: "10.0",
        glasdoel: "H",
        slijpen: "RP",
        geenGlas: 0,
        gehard: 1,
        bewerktekant: "V",
        draairichting: "N",
      },
      {
        title: "Glassoort.AcideKlaar06",
        inNex: true,
        inFlex: false,
        img: "GehardAcideKlaar10.png",
        drawimg: "AcideKlaar.png",
        glassoort: "M",
        type: "H06M",
        glasdikte: "6.0",
        glasdoel: "HD",
        slijpen: "RP",
        geenGlas: 0,
        gehard: 1,
        bewerktekant: "V",
        draairichting: "N",
      },
      {
        title: "Glassoort.AcideExtraKlaar10",
        inNex: false,
        inFlex: true,
        img: "GehardAcideExtraKlaar10.png",
        drawimg: "AcideExtraKlaar.png",
        glassoort: "M1",
        type: "H10M1",
        glasdikte: "10.0",
        glasdoel: "H",
        slijpen: "RP",
        geenGlas: 0,
        gehard: 1,
        bewerktekant: "V",
        draairichting: "N",
      },
      {
        title: "Glassoort.AcideExtraKlaar06",
        inNex: true,
        inFlex: false,
        img: "GehardAcideExtraKlaar10.png",
        drawimg: "AcideExtraKlaar.png",
        glassoort: "M1",
        type: "H06M1",
        glasdikte: "6.0",
        glasdoel: "HD",
        slijpen: "RP",
        geenGlas: 0,
        gehard: 1,
        bewerktekant: "V",
        draairichting: "N",
      },
      {
        title: "Glassoort.Raywall8",
        inNex: false,
        inFlex: true,
        img: "GehardRaywall8.png",
        drawimg: "FloatKlaar.png",
        glassoort: "RAY",
        type: "H08RAY",
        glasdikte: "8.0",
        glasdoel: "H",
        slijpen: "RP",
        geenGlas: 0,
        gehard: 1,
        bewerktekant: "V",
        draairichting: "N",
        patroonrichting: "H",
      },
      {
        title: "Glassoort.DarkGrey",
        inNex: true,
        inFlex: false,
        img: "DarkGrey.png",
        drawimg: "FloatGrijs.png",
        glassoort: "C1",
        type: "H06C1",
        glasdikte: "6.0",
        glasdoel: "HD",
        slijpen: "RP",
        geenGlas: 0,
        gehard: 1,
        bewerktekant: "",
        draairichting: "N",
        patroonrichting: "",
      },
      {
        title: "Glassoort.Crepi06",
        inNex: true,
        inFlex: false,
        img: "GehardKlaar10.png",
        drawimg: "FloatKlaar.png",
        glassoort: "E1",
        type: "H06E1",
        glasdikte: "6.0",
        glasdoel: "HD",
        slijpen: "RP",
        geenGlas: 0,
        gehard: 1,
        bewerktekant: "V",
        draairichting: "N",
        patroonrichting: "",
      },
      {
        title: "Glassoort.Kathedraal6",
        inNex: true,
        inFlex: false,
        img: "GehardKlaar10.png",
        drawimg: "KathedraalKlein.png",
        glassoort: "C20",
        type: "H06G",
        glasdikte: "6.0",
        glasdoel: "HD",
        slijpen: "RP",
        geenGlas: 0,
        gehard: 1,
        bewerktekant: "V",
        draairichting: "N",
        patroonrichting: "H",
      },
    ],
    deurtype: ["Paneel", "Enkel", "Dubbel"],
    schuifdeurZijspeling: 7,
    drawings: [],
    helpProperty: {
      deurgrepen: false,
      deurkader: false,
      deurmaten: false,
      draipunt: false,
      onderverdeling: false,
      ralkleur: false,
      lakafwerking: false,
      anodic: false,
      astools: false,
      scharnieren: false,
      scharnierKleur: false,
      trekkermetslot: false,
      magneetsluiting: false,
      positiescharnieren: false,
      greeptype: false,
    },
  },
  getters: {
    getCustomizationNumber: () => {
      const url = window.location;
      let domain = new URL(url);
      if (domain.hostname == "glasvandoren.alusta.be") return 1;
      else if (domain.hostname == "jensz.alusta.be") return 2;
      else if (domain.hostname == "dutchglassdesign.alusta.be") return 3;
      else if (domain.hostname == "verdouw.alusta.be") return 4;
      else if (domain.hostname == "camver.alusta.be") return 5;
      else if (domain.hostname == "glassdesign.alusta.be") return 6;
      else if (domain.hostname == "glasconcept-vr.alusta.be") return 7;
      else return 0;
    },
    getCoverX: (state) => (x) => {
      let closestX = state.verticaleOnderverdelingen.reduce(
        (prev, curr) => (curr < x && (curr > prev || prev == 0) ? curr : prev),
        0
      );
      return closestX;
    },
    getCoverY: (state) => (y) => {
      let closestX = state.horizontaleOnderverdelingen.reduce(
        (prev, curr) => (curr < y && (curr > prev || prev == 0) ? curr : prev),
        0
      );
      return closestX;
    },
    getBottomHorizontalSeperatorY: (state) => {
      const horizontaleOnderverdelingList =
        !state.asymmetrisch || state.asymmetrisch == 0
          ? [...state.horizontaleOnderverdelingenSym]
          : [...state.horizontaleOnderverdelingen];
      let y = horizontaleOnderverdelingList.reduce(
        (prev, curr) => (curr < prev || prev == 0 ? curr : prev),
        0
      );
      if (y == 0) y = state.kadermaat.hoogte;
      return y;
    },
    getGlassoort: (state) => {
      const productAssortiment = state.productAssortiment;
      const glassoort = state.glassoort;
      return glassoort
        .map((model, index) => ({
          ...model,
          originalIndex: index,
        }))
        .filter((model) =>
          productAssortiment === 0 ? model.inFlex : model.inNex
        );
    },
    getMaxBreedteInfo: (state) => {
      if (state.productAssortiment == 0) {
        return 1500;
      } else {
        if (state.deurkaderKeuze == 2) {
          return 1000;
        } else if (state.deurkaderKeuze == 0) {
          return 1035;
        } else if (state.deurkaderKeuze == 1) {
          return 1085;
        }
      }
    },
    getMaxHoogteInfo: (state) => {
      if (state.productAssortiment == 0) {
        return 3000;
      } else {
        if (state.deurkaderKeuze == 2) {
          return 3000;
        } else if (state.deurkaderKeuze == 0) {
          return 3025;
        } else if (state.deurkaderKeuze == 1) {
          return 3050;
        }
      }
    },
    isMaxBreedteOK: (state) => {
      if (state.productAssortiment == 0) {
        if (state.kadermaat.Breedte > 1500) return false;
      } else {
        if (state.kadermaat.Breedte > 1000) return false;
      }
      return true;
    },
    isMinBreedteOK: (state) => {
      if (state.kadermaat.Breedte < 100) return false;
      return true;
    },
    isMaxHoogteOK: (state) => {
      if (state.kadermaat.Hoogte > 3000) return false;
      return true;
    },
    isMinHoogteOK: (state) => {
      if (state.kadermaat.Hoogte < 100) return false;
      return true;
    },
    isGewichtOK: (state) => {
      if (state.gewicht > 100) {
        return false;
      }
      return true;
    },
    isHoekgreep: (state) => {
      return (
        state.hendelKeuze === 0 ||
        (state.hendelKeuze >= 9 && state.hendelKeuze <= 14)
      );
    },
    isAMURSQ: (state) => {
      return (
        (state.hendelKeuze >= 5 && state.hendelKeuze <= 8) ||
        (state.hendelKeuze >= 28 && state.hendelKeuze <= 29)
      );
    },
    isAMT: (state) => {
      return state.hendelKeuze >= 1 && state.hendelKeuze <= 4;
    },
    isLGreepNEXHG: (state) => {
      return state.hendelKeuze >= 15 && state.hendelKeuze <= 20;
    },
    isLGreepNEXEG: (state) => {
      return state.hendelKeuze >= 21 && state.hendelKeuze <= 26;
    },
    isKantelkruk: (state) => {
      return state.hendelKeuze == 27;
    },
  },
  mutations: {
    setDebug(state, value) {
      state.debug = value;
    },
    addHiddenRemark(state, value) {
      state.hiddenRemark += value + "\n";
    },
    setQouteField(state, payload) {
      state.qouteForm[payload.key] = payload.value;
    },
    toggleHelpProperty(state, value) {
      state.helpProperty[value] = !state.helpProperty[value];
    },
    resetAllHelpProperties(state) {
      Object.keys(state.helpProperty).forEach((key) => {
        state.helpProperty[key] = false;
      });
    },
    setOrderID(state, value) {
      state.orderID = value;
    },
    setAantalDeuren(state, value) {
      state.aantalDeuren = value;
      this.commit("setBreedte", state.kadermaat.Breedte);
    },
    setDeurkaderKeuze(state, value) {
      state.deurkaderKeuze = value;
    },
    setDeurkaderSpeling(state, payload) {
      switch (payload.id) {
        case 1:
          state.deurkaderSpeling.NexRechts = parseInt(payload.value) || 0;
          break;
        case 2:
          state.deurkaderSpeling.NexOnder = parseInt(payload.value) || 0;
          break;
        case 3:
          state.deurkaderSpeling.NexBoven = parseInt(payload.value) || 0;
          break;
      }
    },
    setproductAssortiment(state, value) {
      state.productAssortiment = value;
    },
    setproductType(state, value) {
      state.productType = value;
    },
    setAantalPanelenLinks(state, value) {
      value = value * 1;
      const orig = [...state.panelenLinks];
      state.panelenLinks = [];
      state.aantalPanelenLinks = value;
      for (let i = 0; i < value; i++) {
        if (!orig[i]) state.panelenLinks[i] = 600;
        else state.panelenLinks[i] = orig[i];
      }
      this.commit("setBreedte", state.kadermaat.Breedte);
    },
    updatePaneelLinks(state, payload) {
      state.panelenLinks[payload.id] = payload.value * 1;
      this.commit("setBreedte", state.kadermaat.Breedte);
    },
    setAantalPanelenRechts(state, value) {
      value = value * 1;
      const orig = [...state.panelenRechts];
      state.panelenRechts = [];
      state.aantalPanelenRechts = value;
      for (let i = 0; i < value; i++) {
        if (!orig[i]) state.panelenRechts[i] = 600;
        else state.panelenRechts[i] = orig[i];
      }
      this.commit("setBreedte", state.kadermaat.Breedte);
    },
    updatePaneelRechts(state, payload) {
      state.panelenRechts[payload.id] = payload.value * 1;
      this.commit("setBreedte", state.kadermaat.Breedte);
    },
    setToken(state, value) {
      state.token = value;
    },
    setUserName(state, payload) {
      state.sec.user_name = payload;
    },
    setUserPwd(state, payload) {
      state.sec.user_pwd = payload;
    },
    setDraairichting(state, payload) {
      state.draairichting = parseInt(payload);
      state.redrawFix++;
    },
    setGlassoortKeuze(state, payload) {
      state.glassoortKeuze = parseInt(payload);
      state.priceFix++;
      state.redrawFix++;
    },
    setHendelKeuze(state, payload) {
      state.hendelKeuze = parseInt(payload);
      state.greeplengte = parseInt(state.hendel[state.hendelKeuze].greeplengte);
      state.priceFix++;
      state.redrawFix++;
    },
    setHendelPositieX(state, payload) {
      state.hendelPositie.x = parseInt(payload);
      state.redrawFix++;
    },
    setMagneetPositieY(state, payload) {
      state.magneetPositie.y = parseInt(payload);
      state.redrawFix++;
    },
    setHendelPositieY(state, payload) {
      state.hendelPositie.y = parseInt(payload);
      state.redrawFix++;
    },
    setGreeplengte(state, value) {
      state.greeplengte = parseInt(value);
      state.redrawFix++;
      state.priceFix++;
    },
    setOpvulvlak(state, payload) {
      state.opvulvlak = payload;
      state.redrawFix++;
      state.priceFix++;
    },
    setSlot(state, payload) {
      state.slot = payload;
      state.redrawFix++;
      state.priceFix++;
    },
    setSlotX(state, payload) {
      state.slotX = payload * 1;
      state.redrawFix++;
    },
    setSlotPot(state, payload) {
      state.slotpot = payload;
      state.redrawFix++;
      state.priceFix++;
    },
    setCilinderKleur(state, payload) {
      state.cilinderkleur = payload;
      state.redrawFix++;
      state.priceFix++;
    },
    setCilinderType(state, payload) {
      state.cilindertype = payload;
      state.redrawFix++;
      state.priceFix++;
    },
    setMiddenslot(state, payload) {
      state.middenslot = payload;
      state.redrawFix++;
      state.priceFix++;
    },
    setSlotplaat(state, payload) {
      state.slotplaat = payload;
      state.redrawFix++;
      state.priceFix++;
    },
    setKantschuifslot(state, payload) {
      state.kantschuifslot = payload;
      state.redrawFix++;
      state.priceFix++;
    },
    setLakkleur(state, payload) {
      state.lakkleur = payload;
      state.redrawFix++;
      state.priceFix++;
    },
    setLakAfwerking(state, payload) {
      state.lakafwerking = payload;
      state.priceFix++;
    },
    setALOkeuze(state, value) {
      state.ALOkeuze = value;
      state.priceFix++;
    },
    setHorizontaleOnderverdeling(state, payload) {
      state.horizontaleOnderverdeling = parseInt(payload);
      state.redrawFix++;
      state.priceFix++;
    },
    updateHorizontaleOnderverdeling(state, payload) {
      state.horizontaleOnderverdelingen[payload.id] = payload.value * 1;
      state.redrawFix++;
      state.priceFix++;
    },
    setHorizontaleOnderverdelingen(state, payload) {
      state.horizontaleOnderverdelingen = [];
      state.horizontaleOnderverdelingenSym = [];
      const aantal = payload * 1;
      if (aantal > 0) {
        let h1 = Math.round(state.kadermaat.Hoogte / 2);
        let s1 = Math.round(state.glasmaat.Hoogte / 2); //symetrisch
        if (aantal > 1) {
          h1 = Math.round(state.kadermaat.Hoogte / 3);
          s1 = Math.round(state.glasmaat.Hoogte / 3);
          let h2 = Math.round((state.kadermaat.Hoogte / 3) * 2);
          let s2 = Math.round((state.glasmaat.Hoogte / 3) * 2);
          if (aantal > 2) {
            h1 = Math.round(state.kadermaat.Hoogte / 4);
            s1 = Math.round(state.glasmaat.Hoogte / 4);
            h2 = Math.round(state.kadermaat.Hoogte / 2);
            s2 = Math.round(state.glasmaat.Hoogte / 2);
            let h3 = Math.round((state.kadermaat.Hoogte / 4) * 3);
            let s3 = Math.round((state.glasmaat.Hoogte / 4) * 3);
            state.horizontaleOnderverdelingen.push(h3);
            state.horizontaleOnderverdelingenSym.push(s3);
          }
          state.horizontaleOnderverdelingen.push(h2);
          state.horizontaleOnderverdelingenSym.push(s2);
        }
        state.horizontaleOnderverdelingen.push(h1);
        state.horizontaleOnderverdelingenSym.push(s1);
      }
      state.redrawFix++;
      state.priceFix++;
    },
    setVerticaleOnderverdeling(state, payload) {
      state.verticaleOnderverdeling = parseInt(payload);
      state.redrawFix++;
      state.priceFix++;
    },
    updateVerticaleOnderverdeling(state, payload) {
      state.verticaleOnderverdelingen[payload.id] = payload.value * 1;
      state.redrawFix++;
      state.priceFix++;
    },
    setVerticaleOnderverdelingen(state, payload) {
      state.verticaleOnderverdelingen = [];
      state.verticaleOnderverdelingenSym = [];
      const aantal = payload * 1;
      if (aantal > 0) {
        let v1 = Math.round(state.kadermaat.Breedte / 2);
        let s1 = Math.round(state.glasmaat.Breedte / 2); // symetrische
        if (aantal > 1) {
          v1 = Math.round(state.kadermaat.Breedte / 3);
          s1 = Math.round(state.glasmaat.Breedte / 3);
          let v2 = Math.round((state.kadermaat.Breedte / 3) * 2);
          let s2 = Math.round((state.glasmaat.Breedte / 3) * 2);
          state.verticaleOnderverdelingen.push(v2);
          state.verticaleOnderverdelingenSym.push(s2);
        }
        state.verticaleOnderverdelingen.push(v1);
        state.verticaleOnderverdelingenSym.push(s1);
      }
      state.redrawFix++;
      state.priceFix++;
    },
    setAsymmetrisch(state, value) {
      state.asymmetrisch = value;
      state.redrawFix++;
      state.priceFix++;
    },
    updateScharnieren(state, payload) {
      state.scharnieren[payload.id] = payload.value * 1;
    },
    setScharnierenPositieType(state, value) {
      state.scharnierenPositieType = value;
    },
    setScharnierType(state, value) {
      state.scharnierType = value;
    },
    setScharnierKleur(state, value) {
      state.scharnierKleur = value;
      state.redrawFix++;
      state.priceFix++;
    },
    setGlasBreedte(state, payload) {
      state.glasmaat.Breedte = parseInt(payload);
    },
    setGlasHoogte(state, payload) {
      state.glasmaat.Hoogte = parseInt(payload);
    },
    setBreedte(state, payload) {
      let temp = parseInt(payload);
      if (isNaN(temp)) {
        temp = 0;
      }
      if (state.productAssortiment == 0) {
        state.kadermaat.Breedte = temp;
        state.glasmaat.Breedte = Math.floor(
          state.kadermaat.Breedte -
            state.kaderNaarGlasMaat.FlexLinks -
            state.kaderNaarGlasMaat.FlexRechts
        );
        if (state.aantalDeuren >= 1) {
          if (state.kadermaat.Breedte <= 900) state.alustaDeurKeuze = 0;
          else state.alustaDeurKeuze = 1;
        } else {
          if (state.kadermaat.Breedte <= 500) state.alustaDeurKeuze = 0;
          else if (state.kadermaat.Breedte <= 1000) state.alustaDeurKeuze = 1;
          else state.alustaDeurKeuze = 2;
        }
        if (state.aantalDeuren == 0)
          state.spanmaat.Breedte = state.kadermaat.Breedte + 4;
        if (state.aantalDeuren > 0)
          state.spanmaat.Breedte = state.kadermaat.Breedte + 8;
        if (state.aantalDeuren == 2)
          state.spanmaat.Breedte =
            state.kadermaat.Breedte + state.spanmaat.Breedte + 4;
        state.spanmaat.Breedte =
          state.spanmaat.Breedte + state.aantalPanelenLinks * 2;
        state.spanmaat.Breedte =
          state.spanmaat.Breedte + state.aantalPanelenRechts * 2;

        state.panelenLinks.forEach((value) => {
          state.spanmaat.Breedte = state.spanmaat.Breedte + value;
        });
        state.panelenRechts.forEach((value) => {
          state.spanmaat.Breedte = state.spanmaat.Breedte + value;
        });
      } else {
        // temp is nu deurkader breedte (behalve bij zonder)
        let spelingspan = 1.5 * 2;
        let spelingkader =
          state.deurkaderSpeling.NexLinks + state.deurkaderSpeling.NexRechts;
        let spelingglas = 24;
        if (state.deurkaderKeuze == 0) {
          spelingkader = spelingkader + 30;
        } else if (state.deurkaderKeuze == 1) {
          spelingkader = spelingkader + 80;
        } else {
          spelingkader = 0;
          spelingspan = 5;
        }
        state.deurkadermaat.Breedte = temp;
        state.spanmaat.Breedte = temp + spelingspan;
        state.kadermaat.Breedte = temp - spelingkader;
        state.glasmaat.Breedte = state.kadermaat.Breedte - spelingglas;
      }
      state.priceFix++;
      state.redrawFix++;
    },
    setHoogte(state, payload) {
      let temp = parseInt(payload);
      if (isNaN(temp)) {
        temp = 0;
      }
      if (state.productAssortiment == 0) {
        state.kadermaat.Hoogte = temp;
        state.glasmaat.Hoogte = Math.floor(
          state.kadermaat.Hoogte -
            state.kaderNaarGlasMaat.FlexBoven -
            state.kaderNaarGlasMaat.FlexOnder
        );
        state.spanmaat.Hoogte = Math.floor(state.kadermaat.Hoogte + 19);
      } else {
        // temp is nu deurkader hoogte (behalve bij zonder)
        let spelingspan = 1.5 * 2;
        let spelingkader =
          state.deurkaderSpeling.NexBoven + state.deurkaderSpeling.NexOnder;
        let spelingglas = 24;
        if (state.deurkaderKeuze == 0) {
          spelingkader = spelingkader + 15;
        } else if (state.deurkaderKeuze == 1) {
          spelingkader = spelingkader + 40;
        } else {
          spelingkader = 0;
          spelingspan = 10;
        }
        state.deurkadermaat.Hoogte = temp;
        state.spanmaat.Hoogte = temp + spelingspan;
        state.kadermaat.Hoogte = temp - spelingkader;
        state.glasmaat.Hoogte = state.kadermaat.Hoogte - spelingglas;
      }
      state.priceFix++;
      state.redrawFix++;
    },
    setGewicht(state) {
      const m2 =
        ((state.kadermaat.Hoogte / 1000) * state.kadermaat.Breedte) / 1000;
      state.gewicht = m2 * 25;
    },
    setDraaipuntX(state, payload) {
      let temp = parseInt(payload);
      if (isNaN(temp)) {
        temp = 0;
      }
      state.draaipuntX = temp;
      state.redrawFix++;
    },
    calculateX(state) {
      if (state.kadermaat.Breedte < 1010) {
        state.draaipuntX = 110;
      } else {
        state.draaipuntX = state.kadermaat.Breedte - 900;
      }
    },
    calculateDraaipuntWarning(state) {
      if (state.kadermaat.Breedte - state.draaipuntX > 900) {
        state.toonDraaipuntWaarschuwing = true;
      } else {
        state.toonDraaipuntWaarschuwing = false;
      }
    },
    setSchuifdeurZijspeling(state, payload) {
      state.schuifdeurZijspeling = parseInt(payload);
    },
    setKlantRef(state, payload) {
      state.order.klantref = payload;
    },
    setDeurgeheelRef(state, payload) {
      state.deurgeheelRef = payload;
    },
    setAstool1keuze(state, value) {
      state.astool1keuze = value;
      state.priceFix++;
    },
    setAstool2keuze(state, value) {
      state.astool2keuze = value;
      state.priceFix++;
    },
    toggleShowPrice(state) {
      state.showPrice = !state.showPrice;
    },
  },
  actions: {
    async sso() {
      const sso = await axios.get(
        "https://egdesign.euroglas-dl.com/egdesign/api/sso.php"
      );
      console.log(sso);
    },
    async sendMail({ state, getters }, language) {
      var controller = "sendMail";
      let mailTo = [];
      var customizationNumber = getters.getCustomizationNumber;
      if (customizationNumber == 1)
        mailTo.push({
          mail: "offerte@glasvandoren.be",
          name: "Offerte Glas Vandoren",
        });
      else if (customizationNumber == 2)
        mailTo.push({ mail: "bart@busytec.com", name: "Busytec" });
      else if (customizationNumber == 3)
        mailTo.push({
          mail: "info@dutchglassdesign.nl",
          name: "Dutchglassdesign",
        });
      else if (customizationNumber == 4)
        mailTo.push({ mail: "bart@busytec.com", name: "Busytec" });
      else if (customizationNumber == 5)
        mailTo.push({ mail: "info@camver.be", name: "Camver" });
      else if (customizationNumber == 6)
        mailTo.push({ mail: "info@glassdesign.be", name: "Glassdesign" });
      else if (customizationNumber == 7)
        mailTo.push({ mail: "info@glasconcept-vr.be", name: "Glasconcept-vr" });
      else mailTo.push({ mail: "info@alusta.be", name: "Info Alusta" });
      try {
        const mailResult = await axios.post(
          "https://api.euroglas-dl.com/",
          JSON.stringify({
            controller: controller,
            mail: {
              to: mailTo,
              bcc: [
                { mail: "bart@busytec.com", name: "Busytec" },
                { mail: "roel@icomoda.be", name: "Icomoda" },
              ],
              subject: "Vraag over een Alusta configuratie",
              body:
                "<strong>Dealer: </strong> " +
                state.qouteForm.dealer +
                "<br /><strong>Naam: </strong> " +
                state.qouteForm.lastname +
                "<br /><strong>Voornaam: </strong> " +
                state.qouteForm.firstname +
                "<br /><strong>Tel: </strong> " +
                state.qouteForm.phone +
                "<br /><strong>Mail: </strong> " +
                state.qouteForm.mail +
                "<br /><strong>Straat + nr: </strong> " +
                state.qouteForm.street +
                "<br /><strong>Postcode: </strong> " +
                state.qouteForm.zip +
                "<br /><strong>Gemeente: </strong> " +
                state.qouteForm.municipality +
                "<br /><strong>Land: </strong> " +
                state.qouteForm.country +
                "<br /><strong>Taal: </strong> " +
                language +
                "<br /><strong>Referentie: </strong> " +
                state.qouteForm.lastname +
                " " +
                state.qouteForm.firstname +
                " " +
                state.order.klantref,
            },
          })
        );
        console.log(mailResult);
      } catch (e) {
        console.log(e);
      }
    },
    async saveToEGDesignAdmin(_, order) {
      //Save order to egdesign
      var controller = "saveOrderToAdmin";
      const url = window.location;
      let domain = new URL(url);
      try {
        const saveOrder = await axios.post(
          "https://api.euroglas-dl.com/",
          JSON.stringify({
            controller: controller,
            order: order,
            requestUrl: domain.hostname,
          })
        );
        return saveOrder.data.orderid;
      } catch (e) {
        console.log(e);
      }
      return 0;
    },
    async saveToEGDesign({ state }, order) {
      //Save order to egdesign
      var controller = "saveOrder";
      if (state.orderID != 0) {
        order.orderID = state.orderID;
        controller = "appendGlasspieceToOrder";
      }
      try {
        const saveOrder = await axios.post(
          "https://api.euroglas-dl.com/",
          JSON.stringify({
            sec: {
              user_name: state.sec.user_name,
              user_pwd: state.sec.user_pwd,
              token: state.token,
            },
            controller: controller,
            order: order,
          })
        );
        return saveOrder.data.orderid;
      } catch (e) {
        console.log(e);
      }
      return 0;
    },
    async getLoginToken({ state }) {
      //get login token
      var controller = "getToken";
      try {
        const getToken = await axios.post(
          "https://api.euroglas-dl.com/",
          JSON.stringify({
            sec: {
              user_name: state.sec.user_name,
              user_pwd: state.sec.user_pwd,
            },
            controller: controller,
          })
        );
        return getToken.data.token;
      } catch (e) {
        console.log(e);
      }
      return 0;
    },
    async getDrawings({ state, dispatch }) {
      const theOrder = await dispatch("generateOrderJson");
      if (theOrder != 0) {
        //get price
        let $obj = {
          controller: "getDrawing",
          draw: { img_type: "svg" },
          orderLine: {},
        };
        state.drawings = [];
        theOrder.deurgehelen.forEach(async (glasstuk) => {
          $obj.orderLine = JSON.stringify(glasstuk);
          try {
            const getDrawing = await axios.post(
              "https://api.euroglas-dl.com/",
              JSON.stringify($obj)
            );
            state.drawings.push(getDrawing.data);
          } catch (e) {
            console.log(e);
          }
        });
      }
    },
    async getPrice({ state, dispatch }) {
      const theOrder = await dispatch("generateOrderJson");
      if (theOrder != 0) {
        //get price
        var controller = "getAlustaPrice";
        try {
          const getPrice = await axios.post(
            "https://api.euroglas-dl.com/",
            JSON.stringify({
              sec: {
                user_name: state.sec.user_name,
                user_pwd: state.sec.user_pwd,
                token: state.token,
              },
              controller: controller,
              order: theOrder,
            })
          );
          return getPrice.data;
        } catch (e) {
          console.log(e);
        }
      }
      return 0;
    },
    async generateOrderJson({ state, getters }) {
      try {
        const order = await axios.post(
          "https://api.euroglas-dl.com/",
          JSON.stringify({
            controller: "getEmptyOrder",
            noCache: false,
          })
        );

        const DeurgeheelTemplate = await axios.post(
          "https://api.euroglas-dl.com/",
          JSON.stringify({
            controller: "getEmptyDeurgeheel",
            noCache: false,
          })
        );
        let Deurgeheel = JSON.parse(JSON.stringify(DeurgeheelTemplate.data));

        const glassoort = state.glassoort[state.glassoortKeuze];
        let glasbreedteAanpassing = 0;
        if (glassoort.glasdikte == "8.0") {
          glasbreedteAanpassing = -2;
        }

        Object.keys(glassoort).forEach((key) => {
          if (key in Deurgeheel) Deurgeheel[key] = glassoort[key];
        });
        Deurgeheel.hoogte = state.glasmaat.Hoogte;
        Deurgeheel.breedte = state.glasmaat.Breedte + glasbreedteAanpassing;
        Deurgeheel.referentie = "Alusta";
        Deurgeheel.aantal = 1;
        Deurgeheel.vignet = 1;
        Deurgeheel.gehard = 1;
        Deurgeheel.referentie = state.deurgeheelRef;

        const emptyUitsparing = await axios.post(
          "https://api.euroglas-dl.com/",
          JSON.stringify({
            controller: "getEmptyUitsparing",
            noCache: false,
          })
        );

        //SET KLEUR
        let kleur = state.finishes[state.lakkleur][state.lakafwerking];
        let kleurHendel = state.finishes[state.lakkleur][state.lakafwerking];
        let finishID = 146; //RAL  naar keuze
        let finishIDHendel = 146;
        let finishIDScharnier = 146;
        if (state.lakkleur == 0 && state.lakafwerking == 0) {
          kleur = "";
          finishID = 173; // enkel als lakkleur = 0 gebruiken.
          kleurHendel = "";
          finishIDHendel = 173; // enkel als lakkleur = 0 gebruiken.
        }
        if (state.scharnierKleur == 0) {
          //Tijdelijk Normaal gewoon gelijk aan finishID. Enkel 227 bij scharnierkleur == 1
          finishIDScharnier = finishID == 173 ? 227 : finishID;
        } else if (state.scharnierKleur == 1) {
          finishIDScharnier = 227;
        } else {
          //INOX
          finishIDScharnier = 189;
        }
        //FRAME
        let frame = { ...emptyUitsparing.data };
        let selectedFrame = {};
        if (state.productType == 0)
          selectedFrame = state.ALF[state.alustaDeurKeuze];
        else if (state.productType == 1)
          selectedFrame = state.aldv[state.alustaDeurKeuze];
        else selectedFrame = state.ALSD[0];
        //Overwrite selected frame for NEX
        if (state.productAssortiment == 1) {
          selectedFrame = state.ALF[3];
        }
        Object.keys(selectedFrame).forEach((key) => {
          if (key in frame) frame[key] = selectedFrame[key];
        });

        if (state.productType == 1 && state.productAssortiment == 0)
          frame.x = Math.floor(
            state.draaipuntX - state.kaderNaarGlasMaat.FlexLinks
          ); //X afstand voor uitsparing draaipunt
        if (state.productType == 2)
          frame.zijspeling = state.schuifdeurZijspeling;

        frame.kleur = kleur;
        frame.finishID = finishID;
        Deurgeheel.uitsparing.push(frame);

        //SCHARNIEREN NEX
        if (state.productAssortiment == 1) {
          // Keuze DF of NEX
          // Keuze L of R
          let selectedScharnierTypeID = 0;
          if (state.scharnierType == 0) {
            if (state.draairichting == 0) {
              selectedScharnierTypeID = 0;
            } else selectedScharnierTypeID = 1;
          } else {
            if (state.draairichting == 0) {
              selectedScharnierTypeID = 2;
            } else selectedScharnierTypeID = 3;
          }
          const selectedScharnier =
            state.scharnierTypes[selectedScharnierTypeID];
          // V4 ja of nee.
          const scharnierCount = 3 + (state.kadermaat.Hoogte > 2400 ? 1 : 0);
          for (let scharnierI = 0; scharnierI < scharnierCount; scharnierI++) {
            // invullen alle scharnieren.
            let scharnier = { ...emptyUitsparing.data };
            Object.keys(selectedScharnier).forEach((key) => {
              if (key in scharnier) scharnier[key] = selectedScharnier[key];
            });
            // positie aanpassing.
            scharnier.y =
              state.scharnieren[scharnierI] - state.kaderNaarGlasMaat.NexBoven;

            // V1 en V4 oorsprong B naar O
            if (scharnierI == 0 || scharnierI == 3) {
              scharnier.oorsprong = scharnier.oorsprong.replace("B", "O");
            }
            // KLEUR
            scharnier.kleur = kleur;
            scharnier.finishID = finishIDScharnier;
            Deurgeheel.uitsparing.push(scharnier);
          }
        }

        //DEURKADER NEX
        if (state.productAssortiment == 1 && state.deurkaderKeuze != 2) {
          // type deurkader NEX OF GP
          // state.deurkaderKeuze == 0 NEX 1=GP
          // state.draairichting == 0 L 1=R
          let selectedDKID = 0;
          if (state.deurkaderKeuze == 0) {
            if (state.draairichting == 0) selectedDKID = 0;
            else selectedDKID = 1;
          } else {
            if (state.draairichting == 0) selectedDKID = 2;
            else selectedDKID = 3;
          }
          //invullen deurkader
          const selectedDK = state.deurkaderTypes[selectedDKID];
          let DK = { ...emptyUitsparing.data };
          Object.keys(selectedDK).forEach((key) => {
            if (key in DK) DK[key] = selectedDK[key];
          });
          //speling aanpassing
          DK.speling = state.deurkaderSpeling.NexOnder;
          DK.spelingBoven = state.deurkaderSpeling.NexBoven;
          DK.spelingBreedte =
            state.deurkaderSpeling.NexLinks + state.deurkaderSpeling.NexRechts;
          // KLEUR
          DK.kleur = kleur;
          DK.finishID = finishID;
          //toevoegen aan deurgeheel
          Deurgeheel.uitsparing.push(DK);
        }

        //HANDLE
        if (state.productType > 0) {
          let handle = { ...emptyUitsparing.data };
          const hendelKeuze = state.hendel[state.hendelKeuze];
          Object.keys(hendelKeuze).forEach((key) => {
            if (key in handle) handle[key] = hendelKeuze[key];
          });

          handle.y =
            state.hendelPositie.y -
            (state.productAssortiment == 0
              ? state.kaderNaarGlasMaat.FlexOnder
              : state.kaderNaarGlasMaat.NexOnder);
          handle.greeplengte = state.greeplengte;
          if (state.hendelKeuze != 0 && state.hendelKeuze < 9) {
            handle.x = Math.floor(
              state.hendelPositie.x -
                (state.productAssortiment == 0
                  ? state.kaderNaarGlasMaat.FlexRechts
                  : state.kaderNaarGlasMaat.NexRechts)
            );
          }
          handle.kleur = kleurHendel;
          handle.finishID = finishIDHendel;
          Deurgeheel.uitsparing.push(handle);

          //MagneetSluiting NEX
          if (
            state.productAssortiment == 1 &&
            state.hendel[state.hendelKeuze].p_heeftMagneetsluiting == 1
          ) {
            let magneetsluiting = { ...emptyUitsparing.data };
            Object.keys(state.ALA[9]).forEach((key) => {
              if (key in magneetsluiting)
                magneetsluiting[key] = state.ALA[9][key];
            });
            magneetsluiting.finishID = finishID;
            magneetsluiting.kleur = kleur;
            magneetsluiting.y =
              state.magneetPositie.y - state.kaderNaarGlasMaat.NexOnder;
            Deurgeheel.uitsparing.push(magneetsluiting);
          }
          // MIDDENSLOT
          if (state.middenslot == 1) {
            //Middenslot en het is niet de hoekgreep
            if (state.hendelKeuze != 0 && state.hendelKeuze < 9) {
              //voeg het middenslot toe
              let middenslot = { ...emptyUitsparing.data };
              Object.keys(state.ALA[7]).forEach((key) => {
                if (key in middenslot) middenslot[key] = state.ALA[7][key];
              });
              middenslot.y = handle.y; //zelfde als handle
              middenslot.kleur = kleurHendel;
              middenslot.finishID = finishIDHendel;
              Deurgeheel.uitsparing.push(middenslot);
            }
            //Slotplaat ja of nee
            if (state.slotplaat == 1) {
              let slotplaat = { ...emptyUitsparing.data };
              Object.keys(state.ALA[6]).forEach((key) => {
                if (key in slotplaat) slotplaat[key] = state.ALA[6][key];
              });
              slotplaat.y = handle.y; //zelfde als handle
              slotplaat.kleur = kleurHendel;
              slotplaat.finishID = finishIDHendel;
              Deurgeheel.uitsparing.push(slotplaat);
            }
            //cilinders
            //Juiste cilinder
            let cilindermid = { ...emptyUitsparing.data };
            let ALAIndexMid = 2;
            if (state.cilinderkleur == 0 && state.cilindertype == 0) {
              ALAIndexMid = 2;
            } else if (state.cilinderkleur == 0 && state.cilindertype == 1) {
              ALAIndexMid = 3;
            } else if (state.cilinderkleur == 1 && state.cilindertype == 0) {
              ALAIndexMid = 4;
            } else {
              ALAIndexMid = 5;
            }
            Object.keys(state.ALA[ALAIndexMid]).forEach((key) => {
              if (key in cilindermid)
                cilindermid[key] = state.ALA[ALAIndexMid][key];
            });
            Deurgeheel.uitsparing.push(cilindermid);
          }
        }

        //DIVIDERS H
        const dividersExtra =
          !state.asymmetrisch || state.asymmetrisch == 0
            ? 0
            : (state.productAssortiment == 0
                ? state.kaderNaarGlasMaat.FlexOnder
                : state.kaderNaarGlasMaat.NexOnder) * -1;
        const horizontaleOnderverdelingList =
          !state.asymmetrisch || state.asymmetrisch == 0
            ? state.horizontaleOnderverdelingenSym
            : state.horizontaleOnderverdelingen;
        let dividerH = { ...emptyUitsparing.data };
        let dividerHkeuze = null;

        if (state.ALOkeuze == 0) dividerHkeuze = state.ALO[0];
        else dividerHkeuze = state.ALO[2];
        //overschrijf keuze bij NEX config
        if (state.productAssortiment == 1) {
          dividerHkeuze = state.ALO[4];
        }
        Object.keys(dividerHkeuze).forEach((key) => {
          if (key in dividerH) dividerH[key] = dividerHkeuze[key];
        });
        for (let hI = 0; hI < state.horizontaleOnderverdeling; hI++) {
          let dividersH = { ...dividerH };
          dividersH.y = horizontaleOnderverdelingList[hI] + dividersExtra;
          dividersH.kleur = kleur;
          dividersH.finishID = finishID;
          Deurgeheel.uitsparing.push(dividersH);
        }
        //DIVIDER V
        const dividersVExtra = Math.floor(
          !state.asymmetrisch || state.asymmetrisch == 0
            ? 0
            : (state.productAssortiment == 0
                ? state.kaderNaarGlasMaat.FlexLinks
                : state.kaderNaarGlasMaat.NexLinks) * -1
        );
        const verticaleOnderverdelingList =
          !state.asymmetrisch || state.asymmetrisch == 0
            ? state.verticaleOnderverdelingenSym
            : state.verticaleOnderverdelingen;
        let dividerV = { ...emptyUitsparing.data };
        let dividerVkeuze = null;
        if (state.ALOkeuze == 0) dividerVkeuze = state.ALO[1];
        else dividerVkeuze = state.ALO[3];
        //overschrijf keuze bij NEX config
        if (state.productAssortiment == 1) {
          dividerVkeuze = state.ALO[5];
        }
        Object.keys(dividerVkeuze).forEach((key) => {
          if (key in dividerV) dividerV[key] = dividerVkeuze[key];
        });
        for (let vI = 0; vI < state.verticaleOnderverdeling; vI++) {
          let dividerV1 = { ...dividerV };
          dividerV1.x = verticaleOnderverdelingList[vI] + dividersVExtra;
          dividerV1.kleur = kleur;
          dividerV1.finishID = finishID;
          Deurgeheel.uitsparing.push(dividerV1);
        }

        //Opvulvlak
        if (state.opvulvlak == 1 && state.horizontaleOnderverdeling > 0) {
          let opvulvlak = { ...emptyUitsparing.data };
          const opvulvlakID = state.productAssortiment == 0 ? 0 : 1;
          Object.keys(state.ALCOV[opvulvlakID]).forEach((key) => {
            if (key in opvulvlak)
              opvulvlak[key] = state.ALCOV[opvulvlakID][key];
          });
          opvulvlak.hoogte = Math.floor(
            getters.getBottomHorizontalSeperatorY + dividersExtra
          );
          opvulvlak.breedte = state.glasmaat.Breedte;
          opvulvlak.x = 0;
          opvulvlak.y = 0;
          opvulvlak.kleur = kleur;
          opvulvlak.finishID = finishID;
          Deurgeheel.uitsparing.push(opvulvlak);
        }

        //Slot
        if (state.slot == 1 && state.productType > 0) {
          let slot = { ...emptyUitsparing.data };
          Object.keys(state.ALA[0]).forEach((key) => {
            if (key in slot) slot[key] = state.ALA[0][key];
          });
          slot.x = Math.floor(state.slotX - state.kaderNaarGlasMaat.FlexRechts);
          slot.kleur = kleur;
          slot.finishID = finishID;
          Deurgeheel.uitsparing.push(slot);
          if (state.slotpot == 1) {
            let slotpot = { ...emptyUitsparing.data };
            Object.keys(state.ALA[1]).forEach((key) => {
              if (key in slotpot) slotpot[key] = state.ALA[1][key];
            });
            Deurgeheel.uitsparing.push(slotpot);
          }
          //Juiste cilinder
          let cilinder = { ...emptyUitsparing.data };
          let ALAIndex = 2;
          if (state.cilinderkleur == 0 && state.cilindertype == 0) {
            ALAIndex = 2;
          } else if (state.cilinderkleur == 0 && state.cilindertype == 1) {
            ALAIndex = 3;
          } else if (state.cilinderkleur == 1 && state.cilindertype == 0) {
            ALAIndex = 4;
          } else {
            ALAIndex = 5;
          }
          Object.keys(state.ALA[ALAIndex]).forEach((key) => {
            if (key in cilinder) cilinder[key] = state.ALA[ALAIndex][key];
          });
          Deurgeheel.uitsparing.push(cilinder);
        }

        //Kantschuifslot
        if (state.productType == 1 && state.kantschuifslot > 0) {
          let kantschuifslot = { ...emptyUitsparing.data };
          Object.keys(state.ALA[8]).forEach((key) => {
            if (key in kantschuifslot) kantschuifslot[key] = state.ALA[8][key];
          });
          kantschuifslot.y = 70;
          kantschuifslot.finishID = state.kantschuifslot == 1 ? 315 : 316;
          Deurgeheel.uitsparing.push(kantschuifslot);
        }
        //TOOLS
        if (state.astool1keuze > 0) {
          let astool1 = { ...emptyUitsparing.data };
          Object.keys(state.ASTOOL1[state.astool1keuze - 1]).forEach((key) => {
            if (key in astool1) {
              astool1[key] = state.ASTOOL1[state.astool1keuze - 1][key];
            }
          });
          Deurgeheel.uitsparing.push(astool1);
        }
        if (state.astool2keuze > 0) {
          let astool2 = { ...emptyUitsparing.data };
          Object.keys(state.ASTOOL2[state.astool2keuze - 1]).forEach((key) => {
            if (key in astool2) {
              astool2[key] = state.ASTOOL2[state.astool2keuze - 1][key];
            }
          });
          Deurgeheel.uitsparing.push(astool2);
        }
        //Fill Order
        if (
          state.draairichting == 1 &&
          state.aantalDeuren == 1 &&
          (state.productType == 1 || state.productType == 2)
        ) {
          //Spiegel de deur
          Deurgeheel.uitsparing.forEach((uitsparing) => {
            if (uitsparing.oorsprong == "LO")
              uitsparing.oorsprong = uitsparing.oorsprong.replace("LO", "RO");
            else if (uitsparing.oorsprong == "LB")
              uitsparing.oorsprong = uitsparing.oorsprong.replace("LB", "RB");
            else if (uitsparing.oorsprong == "RO")
              uitsparing.oorsprong = uitsparing.oorsprong.replace("RO", "LO");
            else
              uitsparing.oorsprong = uitsparing.oorsprong.replace("RB", "LB");
          });
        }
        order.data.deurgehelen.push(Deurgeheel);
        //Dubbele deur of schuifdeur
        if (state.aantalDeuren == 2) {
          let Deurgeheel2 = JSON.parse(JSON.stringify(Deurgeheel));
          Deurgeheel.dgdeel = 1;
          Deurgeheel2.dgdeel = 1;
          Deurgeheel2.uitsparing.forEach((uitsparing) => {
            if (uitsparing.oorsprong == "LO")
              uitsparing.oorsprong = uitsparing.oorsprong.replace("LO", "RO");
            else if (uitsparing.oorsprong == "LB")
              uitsparing.oorsprong = uitsparing.oorsprong.replace("LB", "RB");
            else if (uitsparing.oorsprong == "RO")
              uitsparing.oorsprong = uitsparing.oorsprong.replace("RO", "LO");
            else
              uitsparing.oorsprong = uitsparing.oorsprong.replace("RB", "LB");
          });
          order.data.deurgehelen.push(Deurgeheel2);
        }

        //Panelen
        //PANELEN LINKS
        state.panelenLinks.forEach((value) => {
          let paneel = JSON.parse(JSON.stringify(DeurgeheelTemplate.data));
          paneel.hoogte = state.glasmaat.Hoogte;
          paneel.breedte =
            Math.floor(
              value -
                state.kaderNaarGlasMaat.FlexLinks -
                state.kaderNaarGlasMaat.FlexRechts
            ) + glasbreedteAanpassing;
          paneel.referentie = "Alusta Links / Gauche";
          paneel.aantal = 1;
          paneel.vignet = 1;
          paneel.gehard = 1;
          Object.keys(glassoort).forEach((key) => {
            if (key in paneel) paneel[key] = glassoort[key];
          });
          let alf = { ...emptyUitsparing.data };
          if (value <= 500) alf = { ...state.ALF[0] };
          else if (value <= 1000) alf = { ...state.ALF[1] };
          else alf = { ...state.ALF[2] };
          alf.kleur = kleur;
          alf.finishID = finishID;
          paneel.uitsparing.push(alf);

          //DIVIDERS H
          let dividerH = { ...emptyUitsparing.data };
          const dividerHkeuze = state.ALO[0];
          Object.keys(dividerHkeuze).forEach((key) => {
            if (key in dividerH) dividerH[key] = dividerHkeuze[key];
          });
          for (let hI = 0; hI < state.horizontaleOnderverdeling; hI++) {
            let dividersH = { ...dividerH };
            dividersH.y = horizontaleOnderverdelingList[hI] + dividersExtra;
            dividersH.kleur = kleur;
            dividersH.finishID = finishID;
            paneel.uitsparing.push(dividersH);
          }
          order.data.deurgehelen.push(paneel);
        });
        // PANELEN RECHTS
        state.panelenRechts.forEach((value) => {
          let paneel = JSON.parse(JSON.stringify(DeurgeheelTemplate.data));
          paneel.hoogte = state.glasmaat.Hoogte;
          paneel.breedte =
            Math.floor(
              value -
                state.kaderNaarGlasMaat.FlexLinks -
                state.kaderNaarGlasMaat.FlexRechts
            ) + glasbreedteAanpassing;
          paneel.referentie = "Alusta Rechts / Droit";
          paneel.aantal = 1;
          paneel.vignet = 1;
          paneel.gehard = 1;
          Object.keys(glassoort).forEach((key) => {
            if (key in paneel) paneel[key] = glassoort[key];
          });
          let alf = { ...emptyUitsparing.data };
          if (value <= 500) alf = { ...state.ALF[0] };
          else if (value <= 1000) alf = { ...state.ALF[1] };
          else alf = { ...state.ALF[2] };
          alf.kleur = kleur;
          alf.finishID = finishID;
          paneel.uitsparing.push(alf);
          //DIVIDERS H
          let dividerH = { ...emptyUitsparing.data };
          const dividerHkeuze = state.ALO[0];
          Object.keys(dividerHkeuze).forEach((key) => {
            if (key in dividerH) dividerH[key] = dividerHkeuze[key];
          });

          for (let hI = 0; hI < state.horizontaleOnderverdeling; hI++) {
            let dividersH = { ...dividerH };
            dividersH.y = horizontaleOnderverdelingList[hI] + dividersExtra;
            dividersH.kleur = kleur;
            dividersH.finishID = finishID;
            paneel.uitsparing.push(dividersH);
          }
          order.data.deurgehelen.push(paneel);
        });
        if (state.token == "INVALID") {
          order.data.klantref =
            state.order.klantref == ""
              ? state.qouteForm.lastname + " " + state.qouteForm.firstname
              : state.order.klantref;
        } else {
          if (state.orderID == 0) order.data.klantref = state.order.klantref;
        }
        order.data.hiddenRemark = state.hiddenRemark;
        console.log(order.data);
        return order.data;
      } catch (e) {
        console.log(e);
      }
      return 0;
    },
  },
  modules: {},
});
