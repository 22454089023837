<template>
  <!-- EXTRA //-->
  <div class="border border-light rounded-3 shadow-sm m-0 p-0 pb-3 mt-5">
    <MainTitle msg="MainTitle.Color" />
    <div class="p-0 pt-2 mb-3 mt-5">
      <BigButton
        :color="$store.state.lakkleuren[0]"
        :msg="'ColorList.Default'"
        :selected="this.isColorType == 0"
        v-on:click.prevent="setLakColorType(0)"
        :width="20"
      />
      <BigButton
        color="all"
        :src="require('@/assets/ColorCircle.png')"
        :msg="'ColorList.Other'"
        imgSize="50px;"
        :selected="this.isColorType == 1"
        v-on:click.prevent="setLakColorType(1)"
        :width="20"
      />
      <BigButton
        color="all"
        :src="require('@/assets/ColorCircle.png')"
        :msg="'ColorList.Anodic'"
        imgSize="50px;"
        :selected="this.isColorType == 2"
        v-on:click.prevent="setLakColorType(2)"
        :width="20"
      />
    </div>
    <BaseInfo
      v-if="this.isColorType != 0"
      :msg="$t('HelpRalkleur.Error01')"
      :error="true"
    />
    <div v-if="this.isColorType == 1">
      <SubTitle
        msg="SubTitle.Ralkleur"
        :showQuestion="true"
        helpProp="ralkleur"
      />
      <HelpRalkleur v-if="$store.state.helpProperty.ralkleur" />
      <template v-for="item in [0, 1, 2, 3, 4]" :key="item">
        <BigButton
          :color="$store.state.lakkleuren[item]"
          :msg="'ColorList.' + item"
          :selected="this.lakkleur == item"
          v-on:click.prevent="setLakkleur(item)"
          :width="20"
        />
      </template>

      <div v-if="this.lakkleur <= 4">
        <SubTitle
          msg="SubTitle.Lakafwerking"
          :showQuestion="true"
          helpProp="lakafwerking"
        />
        <HelpLakafwerking v-if="$store.state.helpProperty.lakafwerking" />
        <div class="m-2">
          <SmallButton
            :msg="'SelecteerKleur.structuur'"
            :selected="this.lakafwerking == 0"
            v-on:click.prevent="setLakAfwerking(0)"
          />
          <SmallButton
            :msg="'SelecteerKleur.mat'"
            :selected="this.lakafwerking == 1"
            v-on:click.prevent="setLakAfwerking(1)"
          />
          <SmallButton
            :msg="'SelecteerKleur.glanzend'"
            :selected="this.lakafwerking == 2"
            v-on:click.prevent="setLakAfwerking(2)"
          />
        </div>
      </div>
    </div>
    <div v-if="this.isColorType == 2">
      <SubTitle msg="SubTitle.Anodic" :showQuestion="true" helpProp="anodic" />
      <HelpAnodic v-if="$store.state.helpProperty.anodic" />
      <template v-for="item in [5, 6, 7, 8, 9, 10, 11]" :key="item">
        <BigButton
          :color="$store.state.lakkleuren[item]"
          :msg="'ColorList.' + item"
          :selected="this.lakkleur == item"
          v-on:click.prevent="setLakkleur(item)"
          :width="20"
        />
      </template>
    </div>
    <HingeColorSelect
      v-if="$store.state.productAssortiment == 1 && this.isColorType != 0"
    />
  </div>
  <!-- END EXTRA //-->
</template>
<script>
import HelpRalkleur from "@/components/help/HelpRalkleur.vue";
import HelpLakafwerking from "@/components/help/HelpLakafwerking.vue";
import HelpAnodic from "@/components/help/HelpAnodic.vue";
import HingeColorSelect from "@/components/HingeColorSelect.vue";
export default {
  name: "ColorSelect",
  components: {
    HelpRalkleur,
    HelpLakafwerking,
    HelpAnodic,
    HingeColorSelect,
  },
  data() {
    return {
      isColorType: 0,
    };
  },
  computed: {
    lakkleur: {
      get() {
        return this.$store.state.lakkleur;
      },
      set(value) {
        this.$store.commit("setLakkleur", value);
      },
    },
    lakafwerking: {
      get() {
        return this.$store.state.lakafwerking;
      },
      set(value) {
        this.$store.commit("setLakAfwerking", value);
      },
    },
  },
  methods: {
    setLakkleur(index) {
      this.lakkleur = index;
    },
    setLakAfwerking(index) {
      this.lakafwerking = index;
    },
    setLakColorType(index) {
      if (index == 0) this.$store.commit("setScharnierKleur", 0);
      if (index == 0 || index == 1) {
        this.lakafwerking = 0;
        this.lakkleur = 0;
      } else {
        this.lakafwerking = 0;
        this.lakkleur = 5;
      }
      this.isColorType = index;
    },
  },
};
</script>
