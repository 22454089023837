<template>
  <div class="drawScreen">
    <div v-if="$store.state.productAssortiment == 1 && false">
      <img src="@/assets/NexTempFoto.jpg" class="img-fluid" />
    </div>
    <div v-show="$store.state.productAssortiment == 0 || true">
      <canvas
        id="mainCanvas"
        class="img-fluid rounded-3"
        width="2048"
        height="1070"
      ></canvas>
      <canvas
        id="blitCanvas"
        class="img-fluid"
        width="2048"
        height="1070"
        style="display: none"
      ></canvas>
      <canvas
        id="colorCanvas"
        class="img-fluid"
        width="10"
        height="10"
        style="display: none"
      ></canvas>
    </div>
    <br />
    <button
      class="btn btn-primary rounded-pill hideOnPrint"
      v-on:click.prevent="DownloadImg()"
      v-show="$store.state.productAssortiment == 0 || true"
    >
      {{ $t("Base.Download") }}
    </button>
    &nbsp;
    <PrintButton v-show="$store.state.productAssortiment == 0 || true" />
  </div>
</template>
<script>
import PrintButton from "@/components/PrintButton.vue";
export default {
  name: "DrawScreen",
  components: {
    PrintButton,
  },
  data() {
    return {
      frontImg: new Image(),
      backImg: new Image(),
      wallImg: new Image(),
      glassImg: new Image(),
      frameBovenhoekL: new Image(),
      frameBovenhoekR: new Image(),
      frameOnderhoekL: new Image(),
      frameOnderhoekR: new Image(),
      frameBoven: new Image(),
      frameOnder: new Image(),
      frameZijkantL: new Image(),
      frameZijkantR: new Image(),
      hoekgreep: new Image(),
      hoekgreepMirror: new Image(),
      greep: new Image(),
      bovenspil: new Image(),
      onderverdelingH: new Image(),
      onderverdelingV: new Image(),
      slotkast: new Image(),
      opvulvlak: new Image(),
      loaded: 0,
      mainCanvas: "",
      mainContext: "",
      scale: 0.16, //0.14 for full width use
      bottomWall: 577,
      center: 0,
    };
  },
  mounted: function () {
    var self = this;

    this.frameBovenhoekL.src = require("@/assets/Frame/BovenhoekL.png");
    this.frameBovenhoekR.src = require("@/assets/Frame/BovenhoekR.png");
    this.frameOnderhoekL.src = require("@/assets/Frame/OnderhoekL.png");
    this.frameOnderhoekR.src = require("@/assets/Frame/OnderhoekR.png");
    this.frameBoven.src = require("@/assets/Frame/Boven.png");
    this.frameOnder.src = require("@/assets/Frame/Onder.png");
    this.frameZijkantL.src = require("@/assets/Frame/ZijkantL.png");
    this.frameZijkantR.src = require("@/assets/Frame/ZijkantR.png");
    this.hoekgreep.src = require("@/assets/Handvaten/Hoekgreep.png");
    this.hoekgreepMirror.src = require("@/assets/Handvaten/Hoekgreep.png");
    this.greep.src = require("@/assets/Handvaten/Trekker.png");
    this.bovenspil.src = require("@/assets/Draaipunt/Bovendraaispil.png");
    this.onderverdelingH.src = require("@/assets/Onderverdeling/latH.png");
    this.onderverdelingV.src = require("@/assets/Onderverdeling/latV.png");
    this.slotkast.src = require("@/assets/Extra/Slotkast.png");
    this.opvulvlak.src = require("@/assets/Onderverdeling/Opvulvlak.png");

    this.wallImg.src = require("@/assets/Interieur/Wall.png");
    this.wallImg.onload = function () {
      self.loadCounter();
    };
    this.backImg.src = require("@/assets/Interieur/Back.png");
    this.backImg.onload = function () {
      self.loadCounter();
    };
    this.frontImg.src = require("@/assets/Interieur/Front.png");
    this.frontImg.onload = function () {
      self.loadCounter();
    };
    this.glassImg.src = require("@/assets/Interieur/Glassoorten/FloatKlaar.png");
    this.glassImg.onload = function () {
      self.loadCounter();
    };

    this.mainCanvas = document.getElementById("mainCanvas");
    this.mainContext = this.mainCanvas.getContext("2d");
    this.center = this.mainCanvas.width / 2;
  },
  methods: {
    loadCounter() {
      this.loaded++;
      if (this.loaded == 4) {
        this.reDraw();
        this.$watch("$store.state.redrawFix", () => {
          var self = this;
          this.changeAllColors();
          setTimeout(function () {
            self.reDraw();
          }, 100);
        });
        this.$watch("$store.state.glassoortKeuze", () => {
          this.setGlassoort();
        });
      }
    },
    DownloadImg() {
      var link = document.createElement("a");
      link.download = "Alusta.jpg";
      link.href = this.mainCanvas.toDataURL("image/jpeg");
      link.click();
    },
    setGlassoort() {
      var self = this;
      this.glassImg.src = require(`@/assets/Interieur/Glassoorten/${
        this.$store.state.glassoort[this.$store.state.glassoortKeuze].drawimg
      }`);
      this.glassImg.onload = function () {
        console.log(self.glassImg.src);
        self.reDraw();
      };
    },
    reDraw() {
      this.mainContext.clearRect(
        0,
        0,
        this.mainCanvas.width,
        this.mainCanvas.height
      );
      this.mainContext.drawImage(
        this.backImg,
        0,
        0,
        this.mainCanvas.width,
        this.mainCanvas.height
      );

      this.mainContext.drawImage(
        this.glassImg,
        0,
        0,
        this.mainCanvas.width,
        this.mainCanvas.height
      );

      this.drawWallGap();
      //Frame
      var x =
        this.center - (this.$store.state.spanmaat.Breedte * this.scale) / 2;
      //x = x + 2; //instead of speling
      if (this.$store.state.productAssortiment == 1) {
        // extra speling tekenen
        if (this.$store.state.draairichting == 1) {
          x = x + this.$store.state.deurkaderSpeling.NexRechts * this.scale;
        }
        if (this.$store.state.deurkaderKeuze == 0) {
          //NEX DEURKADER
          x = x + 1;
        } else if (this.$store.state.deurkaderKeuze == 1) {
          //GP DEURKADER
          x = x + 5;
        }
      }

      //Linkse panelen
      for (var i = 0; i < this.$store.state.aantalPanelenLinks; i++) {
        //x = x + 1 * this.scale;
        this.drawAllSeparatorsH(
          x,
          this.$store.state.panelenLinks[i] * this.scale
        );
        this.drawFrame(
          x,
          this.bottomWall -
            this.$store.state.kadermaat.Hoogte * this.scale -
            (19 * this.scale) / 2,
          this.$store.state.panelenLinks[i] * this.scale,
          this.$store.state.kadermaat.Hoogte * this.scale
        );
        x += this.$store.state.panelenLinks[i] * this.scale;
        //x = x + 1 * this.scale;
      }

      //Deur Links
      if (
        (this.$store.state.draairichting == 0 &&
          this.$store.state.aantalDeuren == 1) ||
        this.$store.state.aantalDeuren != 1
      ) {
        this.drawAllSeparatorsH(
          x,
          this.$store.state.kadermaat.Breedte * this.scale
        );
        this.drawAllSeparatorsV(
          x,
          this.bottomWall -
            this.$store.state.kadermaat.Hoogte * this.scale -
            (19 * this.scale) / 2,
          this.$store.state.kadermaat.Hoogte * this.scale,
          this.$store.state.kadermaat.Breedte * this.scale
        );
        this.drawOpvulvlak(x);
        this.drawFrame(
          x,
          this.bottomWall -
            this.$store.state.kadermaat.Hoogte * this.scale -
            (19 * this.scale) / 2,
          this.$store.state.kadermaat.Breedte * this.scale,
          this.$store.state.kadermaat.Hoogte * this.scale
        );
        //x = x + 4 * this.scale;
        if (this.$store.state.aantalDeuren >= 1) {
          this.drawBovenspil(
            x + this.$store.state.draaipuntX * this.scale,
            this.bottomWall -
              this.$store.state.kadermaat.Hoogte * this.scale -
              19 * this.scale
          );
        }
        x += this.$store.state.kadermaat.Breedte * this.scale;
        if (this.$store.state.aantalDeuren >= 1) {
          this.drawGreep(
            x - this.$store.state.hendelPositie.x * this.scale,
            this.bottomWall - this.$store.state.hendelPositie.y * this.scale
          );
        }
        if (this.$store.state.slot == 1) {
          this.drawSlot(
            x - this.$store.state.slotX * this.scale,
            this.bottomWall
          );
        }
      }
      //gedraaide deur of dubbele deur
      if (
        this.$store.state.aantalDeuren > 1 ||
        (this.$store.state.draairichting == 1 &&
          this.$store.state.aantalDeuren == 1)
      ) {
        this.drawAllSeparatorsH(
          x,
          this.$store.state.kadermaat.Breedte * this.scale
        );
        this.drawAllSeparatorsV(
          x,
          this.bottomWall -
            this.$store.state.kadermaat.Hoogte * this.scale -
            (19 * this.scale) / 2,
          this.$store.state.kadermaat.Hoogte * this.scale,
          0
        );
        this.drawOpvulvlak(x);
        this.drawFrame(
          x,
          this.bottomWall -
            this.$store.state.kadermaat.Hoogte * this.scale -
            (19 * this.scale) / 2,
          this.$store.state.kadermaat.Breedte * this.scale,
          this.$store.state.kadermaat.Hoogte * this.scale
        );
        this.drawBovenspil(
          x +
            this.$store.state.kadermaat.Breedte * this.scale -
            this.$store.state.draaipuntX * this.scale,
          this.bottomWall -
            this.$store.state.kadermaat.Hoogte * this.scale -
            19 * this.scale
        );
        this.drawGreepMirror(
          x + this.$store.state.hendelPositie.x * this.scale,
          this.bottomWall - this.$store.state.hendelPositie.y * this.scale
        );
        //x = x + 4 * this.scale;
        x += this.$store.state.kadermaat.Breedte * this.scale;
        if (this.$store.state.slot == 1) {
          this.drawSlot(
            x -
              this.$store.state.kadermaat.Breedte * this.scale +
              this.$store.state.slotX * this.scale -
              this.slotkast.width * this.scale,
            this.bottomWall
          );
        }
      }
      //Rechtse panelen
      for (i = 0; i < this.$store.state.aantalPanelenRechts; i++) {
        //x = x + 1 * this.scale;
        this.drawAllSeparatorsH(
          x,
          this.$store.state.panelenRechts[i] * this.scale
        );
        this.drawFrame(
          x,
          this.bottomWall -
            this.$store.state.kadermaat.Hoogte * this.scale -
            (19 * this.scale) / 2,
          this.$store.state.panelenRechts[i] * this.scale,
          this.$store.state.kadermaat.Hoogte * this.scale
        );
        x += this.$store.state.panelenRechts[i] * this.scale;
        //x = x + 1 * this.scale;
      }
      //Furniture
      this.mainContext.drawImage(
        this.frontImg,
        0,
        0,
        this.mainCanvas.width,
        this.mainCanvas.height
      );
    },
    drawBovenspil(x, y) {
      this.mainContext.drawImage(
        this.bovenspil,
        x - (this.bovenspil.width / 2) * this.scale,
        y,
        this.bovenspil.width * this.scale,
        this.bovenspil.height * this.scale
      );
    },
    drawSlot(x, y) {
      this.mainContext.drawImage(
        this.slotkast,
        x,
        y -
          (this.frameOnder.height / 2) * this.scale -
          this.slotkast.height * this.scale,
        this.slotkast.width * this.scale,
        this.slotkast.height * this.scale
      );
    },
    drawOpvulvlak(x) {
      const onderkant =
        this.$store.state.productAssortiment == 0
          ? this.$store.state.kaderNaarGlasMaat.FlexOnder
          : this.$store.state.kaderNaarGlasMaat.NexOnder;
      const dividersExtra =
        !this.$store.state.asymmetrisch || this.$store.state.asymmetrisch == 0
          ? 0
          : onderkant * -1;
      if (this.$store.state.opvulvlak == 1) {
        let y = this.bottomWall - onderkant * this.scale;
        let w = this.$store.state.kadermaat.Breedte * this.scale;
        let h =
          (this.$store.getters.getBottomHorizontalSeperatorY + dividersExtra) *
          this.scale;
        this.mainContext.drawImage(this.opvulvlak, x, y, w, h * -1);
      }
    },
    drawGreep(x, y) {
      if (
        this.$store.state.hendelKeuze == 0 ||
        (this.$store.state.hendelKeuze >= 9 &&
          this.$store.state.hendelKeuze <= 14)
      ) {
        this.mainContext.drawImage(
          this.hoekgreep,
          x - (this.hoekgreep.width + 19) * this.scale,
          y - (this.$store.state.greeplengte / 2) * this.scale,
          this.hoekgreep.width * this.scale,
          this.$store.state.greeplengte * this.scale
        );
      } else {
        this.mainContext.drawImage(
          this.greep,
          x - (this.greep.width + 19) * this.scale,
          y -
            (this.$store.state.hendel[this.$store.state.hendelKeuze].p_height /
              2) *
              this.scale,
          this.greep.width * this.scale,
          this.$store.state.hendel[this.$store.state.hendelKeuze].p_height *
            this.scale
        );
      }
    },
    drawGreepMirror(x, y) {
      if (
        this.$store.state.hendelKeuze == 0 ||
        (this.$store.state.hendelKeuze >= 9 &&
          this.$store.state.hendelKeuze <= 14)
      ) {
        this.mainContext.drawImage(
          this.hoekgreep,
          x + 19 * this.scale,
          y - (this.$store.state.greeplengte / 2) * this.scale,
          this.hoekgreep.width * this.scale,
          this.$store.state.greeplengte * this.scale
        );
      } else {
        this.mainContext.drawImage(
          this.greep,
          x + 19 * this.scale,
          y -
            (this.$store.state.hendel[this.$store.state.hendelKeuze].p_height /
              2) *
              this.scale,
          this.greep.width * this.scale,
          this.$store.state.hendel[this.$store.state.hendelKeuze].p_height *
            this.scale
        );
      }
    },
    drawAllSeparatorsH(x, w) {
      for (
        var i = 0;
        i < this.$store.state.horizontaleOnderverdelingen.length;
        i++
      ) {
        this.drawSeparatorgH(
          x,
          this.$store.state.horizontaleOnderverdelingen[i] * this.scale,
          w
        );
      }
    },
    drawSeparatorgH(x, y, w) {
      const onderkant =
        this.$store.state.productAssortiment == 0
          ? this.$store.state.kaderNaarGlasMaat.FlexOnder
          : this.$store.state.kaderNaarGlasMaat.NexOnder;
      // eslint-disable-next-line no-unused-vars
      const dividersExtra =
        !this.$store.state.asymmetrisch || this.$store.state.asymmetrisch == 0
          ? 0
          : onderkant * -1;
      this.mainContext.drawImage(
        this.onderverdelingH,
        x,
        this.bottomWall - y + (this.onderverdelingH.width / 2) * this.scale,
        w,
        this.onderverdelingH.height * this.scale
      );
    },
    drawAllSeparatorsV(x, y, h, offset = 0) {
      const mirror = offset == 0 ? -1 : 1;
      for (
        var i = 0;
        i < this.$store.state.verticaleOnderverdelingen.length;
        i++
      ) {
        this.drawSeparatorgV(
          x +
            offset -
            this.$store.state.verticaleOnderverdelingen[i] *
              this.scale *
              mirror,
          y,
          h
        );
      }
    },
    drawSeparatorgV(x, y, h) {
      this.mainContext.drawImage(
        this.onderverdelingV,
        x - (this.onderverdelingV.width / 2) * this.scale,
        y,
        this.onderverdelingH.width * this.scale,
        h
      );
    },
    drawWallGap() {
      var canvas = document.getElementById("blitCanvas");
      var context = canvas.getContext("2d");
      context.globalCompositeOperation = "source-over";
      context.clearRect(0, 0, this.mainCanvas.width, this.mainCanvas.height);
      // draw a rectangle
      context.beginPath();
      context.drawImage(
        this.wallImg,
        0,
        0,
        this.mainCanvas.width,
        this.mainCanvas.height
      );
      context.fillStyle = "blue";
      context.fill();

      context.globalCompositeOperation = "destination-out";
      context.beginPath();
      let b = this.$store.state.spanmaat.Breedte * this.scale;
      let h = this.$store.state.spanmaat.Hoogte * this.scale;
      context.rect(this.center - b / 2, this.bottomWall - h, b, h);
      context.fillStyle = "#ff0000";
      context.fill();

      //Wall
      this.mainContext.drawImage(
        canvas,
        0,
        0,
        this.mainCanvas.width,
        this.mainCanvas.height
      );
    },
    drawFrame(x, y, w, h) {
      //Top
      this.mainContext.drawImage(
        this.frameBoven,
        x,
        y,
        w,
        this.frameBoven.height * this.scale
      );
      //Bottom
      this.mainContext.drawImage(
        this.frameOnder,
        x,
        y + h - this.frameOnder.height * this.scale,
        w,
        this.frameOnder.height * this.scale
      );
      //Side Left
      this.mainContext.drawImage(
        this.frameZijkantL,
        x,
        y,
        this.frameZijkantL.width * this.scale,
        h
      );
      //Side Right
      this.mainContext.drawImage(
        this.frameZijkantR,
        x + w - this.frameZijkantR.width * this.scale,
        y,
        this.frameZijkantR.width * this.scale,
        h
      );

      //Top corner left
      this.mainContext.drawImage(
        this.frameBovenhoekL,
        x,
        y,
        this.frameBovenhoekL.width * this.scale,
        this.frameBovenhoekL.height * this.scale
      );
      //Top corner Right
      this.mainContext.drawImage(
        this.frameBovenhoekR,
        x + w - this.frameBovenhoekR.width * this.scale,
        y,
        this.frameBovenhoekR.width * this.scale,
        this.frameBovenhoekR.height * this.scale
      );
      //Bottom corner left
      this.mainContext.drawImage(
        this.frameOnderhoekL,
        x,
        y + h - this.frameOnderhoekL.height * this.scale,
        this.frameOnderhoekL.width * this.scale,
        this.frameOnderhoekL.height * this.scale
      );
      //Bottom corner Right
      this.mainContext.drawImage(
        this.frameOnderhoekR,
        x + w - this.frameOnderhoekR.width * this.scale,
        y + h - this.frameOnderhoekR.height * this.scale,
        this.frameOnderhoekR.width * this.scale,
        this.frameOnderhoekR.height * this.scale
      );
    },
    changeAllColors() {
      this.changeColor(this.frameBovenhoekL);
      this.changeColor(this.frameBovenhoekR);
      this.changeColor(this.frameOnderhoekL);
      this.changeColor(this.frameOnderhoekR);
      this.changeColor(this.frameBoven);
      this.changeColor(this.frameOnder);
      this.changeColor(this.frameZijkantL);
      this.changeColor(this.frameZijkantR);
      this.changeColor(this.hoekgreep);
      this.changeColor(this.hoekgreepMirror);
      this.changeColor(this.greep);
      this.changeColor(this.bovenspil);
      this.changeColor(this.onderverdelingH);
      this.changeColor(this.onderverdelingV);
      this.changeColor(this.slotkast);
      this.changeColor(this.opvulvlak);
    },
    changeColor(img) {
      var canvas = document.getElementById("colorCanvas");
      var ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      ctx.globalCompositeOperation = "source-in";
      ctx.fillStyle = this.$store.state.lakkleuren[this.$store.state.lakkleur];
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.globalCompositeOperation = "source-over";
      img.src = canvas.toDataURL("image/png");
    },
  },
};
</script>
